import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import authentication from './modules/authentication'
import retailers from './modules/retailers'
import products  from './modules/products';
import issue from './modules/issue'
import customers from './modules/customers';
import admins from "./modules/admins";

export default createStore({
  modules: {
    authentication: authentication,
    retailers: retailers,
    products: products,
    issue:issue,
    customers:customers,
    admins:admins
  },
  // state: {
  //   enviroment: 'development',
  //   baseurl: 'http://127.0.0.1:8000/api',
  // legacyurl: 'https://legacy.tizeti.com/geniatechapi/index.php'
  // },
  state: {
    enviroment: 'production',
    baseurl: 'https://hotspot.tizetiwifi.com/api',
    legacyurl: 'https://legacy.tizeti.com/geniatechapi/index.php'
  },
  mutations: {
  },
  actions: {
  },
  plugins: [createPersistedState(
    {storage: window.sessionStorage}
  )]
})
