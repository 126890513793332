<template>



<!--  // id:"04cc46b7-879a-400b-8f87-9200700f67d9",-->
<!--  //     activated_status: 'active',-->
<!--  //     type:'customer',-->
<!--  //     customer_number: "+2348163470928",-->
<!--  //     alias:"+2348163470928@expresswifi.com",-->


  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr  >

<!--              <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase ">Customer Details</th>-->
              <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase ">Device Label</th>
              <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase ">Full Name</th>
              <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase ">Phone</th>
              <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase ">Device Mac</th>

              <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase ">Account Alias</th>
               <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Balance</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expiration Date</th>
              <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase ">Type</th>
              <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase ">Recharge</th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">

            <tr v-for="(customer , index) in dummydata" :key="index">
              <td class="px-3 py-4 whitespace-nowrap">
              <div class="flex items-center">

                <input type="hidden"  :value="get_customer_expiration_date(customer.mac_address)">
                <div class="ml-4">
                  <div class="text-sm font-medium text-gray-900 bg-red-100 text-red-800">{{ customer.device_label}}</div>
                </div>
                <div class="ml-4">
                  <div class="text-sm font-medium text-gray-900">{{ customer.customer_number}}</div>
                </div>

              </div>
            </td>

              <td class="px-3 py-4 whitespace-nowrap">
                <div class="flex ">
                  <div class="ml-0">
                    <div class="text-sm font-medium text-gray-900">{{ customer.full_name}}</div>
                  </div>

                </div>
              </td>

                <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex ">
                  <div class="ml-0">
                    <div class="text-sm font-medium text-gray-900">{{ customer.phone}}</div>
                  </div>

                </div>
              </td>


              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex ">
                  <div class="ml-0">
                    <div class="text-sm font-medium text-gray-900">{{ customer.mac_address}}</div>
                  </div>

                </div>
              </td>




              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex">
                  <div class="ml-0">
                    <div class="text-sm font-medium text-gray-900">{{ customer.email}}</div>
                  </div>

                </div>
              </td>

               <td class="px-6 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 capitalize"> {{ customer.data_balance }} </span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 capitalize"> {{mac_expiration[customer.mac_address] }} </span>
              </td>
              <td class="px-3 py-4 whitespace-nowrap">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 capitalize"> Customer </span>
              </td>

              <td class="px-3 py-4 whitespace-nowrap">
                <button class="px-3 py-2 inline-flex text-xs leading-5 font-semibold rounded bg-expressbutton text-white hover:bg-expressgray transition-all ease-in-out duration-150" @click="showModel([customer.mac_address, customer.phone, customer.email])"> Top Up </button>
              </td>

            </tr>

            <!-- More people... -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>



export default {
  created() {
    console.log("testing",  )
    console.log('Table Component Props',this.props)  //undefined;
  },
  name: "Table",
  emits:["click"],
  data(){
    return {
      mac_expiration:{}
    }
  },
  props:{
          ['dummydata']:{ type: Array, required: true }
       },
  methods:{
    async get_customer_expiration_date(username){
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("action", "hotspot_fetch_expiration_date");
      urlencoded.append("username", username);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };
      await fetch("https://legacy.tizeti.com/geniatechapi/index.php", requestOptions)
          .then(response => response.text())
          .then(result => {
            result = JSON.parse(result)
            this.mac_expiration[username] =  result.message
          })
          .catch(error => console.log('error', error));
    },
    showModel: function (selected_data){
     // console.log("table showModel triggered",selected_data)
      this.$emit('click', selected_data );
    }
  }
}
</script>

<style scoped>

</style>
