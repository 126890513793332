<template>
  <div class="total-retailers flex space-x-4 p-8 items-center border-b-2 cursor-pointer hover:bg-gray-200 text-xl">
<!--    <i class="fas fa-signal text-green-400"></i>-->
    <i :class="iconclass"></i>
    <div class="flex flex-col space-y-2">
      <p class="font-light">{{ title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "menuitems",
  props:["iconclass", "title"]
}
</script>

<style scoped>

</style>
