<template>
    <div class="flex flex-col space-y-3">
      <h1 class="font-serif font-base text-lg" > {{title}}</h1>
      <p class="font-light text-base"  :class="colour"> {{description}}</p>
    </div>
</template>

<script>
export default {
  name: "TitleContentCard",
  props:["title","description","colour"]
}
</script>

<style scoped>

</style>
