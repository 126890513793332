<template>
  <div class="w-full bg-white h-full rounded shadow-2xl p-3 flex flex-col">
    <div
      class="img-menu w-full h-44 object-cover flex items-center justify-center mb-3"
    >
      <button
        class="rounded bg-white flex space-x-3 p-4 w-full mx-10 text-center items-center justify-between font-light hover:bg-gray-600 hover:text-white"
        @click="navigation('/create/callcard')"
      >
        Create Call Card
        <i class="fas fa-calendar-alt text-express"></i>
      </button>
    </div>

    <menuitems
      iconclass="fas fa-user-shield text-green-400"
      title="Administrator Overview"
      value=""
      valueclass="bg-green-100 p-2  text-green-400"
      description="General Overview,Account Managements..."
      @click="navigation('/main')"
    />

    <menuitems
      iconclass="fas fa-book text-green-400"
      title="Analytics"
      value=""
      valueclass="bg-green-100 p-2  text-green-400"
      description="Analytics..."
      @click="navigation('/analytics')"
    />

    <menuitems
      iconclass="fas fa-comment-alt text-green-400"
      title="Send Message"
      value="1,340"
      valueclass="bg-green-100 p-2  text-green-400"
      description=""
      @click="navigation('/sendmessage')"
    />
    <menuitems
      iconclass="fas fa-ticket-alt text-green-400"
      title="Support Tickets "
      value="1,340"
      valueclass="bg-green-100 p-2  text-green-400"
      description="Top media concept,Geotech and 1,389 others"
      @click="navigation('/supportticket')"
    />

    <!-- <menuitems
               iconclass="fas fa-signal text-green-400"
               title="Total Retailers"
               value="1,340"
               valueclass="bg-green-100 p-2  text-green-400"
               description="Top media concept,Geotech and 1,389 others"
           />

           <menuitems
               iconclass="fas fa-store text-green-400"
               title="Active Retailers"
               value="1,068"
               valueclass="bg-green-100 p-2  text-green-400"
               description="Top media concept,Geotech and 1,062 others"
           /> -->
    <menuitems
      iconclass="fas fa-trash text-green-400"
      title="To be Decommissioned"
      value="120"
      valueclass="bg-red-100 p-2  text-red-400"
      description="Raytech, Bet9ja, 118 others"
      @click="navigation('/decommissioned')"
    />

    <menuitems
      iconclass="fas fa-user text-green-400"
      title="Data Transfer"
      value="10,284"
      valueclass="bg-green-100 p-2 rounded text-green-400"
      description="Data transfer"
      @click="navigation('/data-transfer')"
    />

    <menuitems
      iconclass="fas fa-signal text-green-400"
      title="Customer Usage History"
      value="120"
      valueclass="bg-red-100 p-2  text-red-400"
      description="Usage History"
      @click="navigation('/users-history')"
    />

    <menuitems
      iconclass="fas fa-users text-green-400"
      title="Customer Purchase History"
      value="10,284"
      valueclass="bg-green-100 p-2 rounded text-green-400"
      description="Williams Chinwa,Peter Akandu and 10,282 others"
      @click="navigation('/users')"
    />

    <menuitems
      iconclass="fas fa-cash-register text-green-400"
      title="Retailer Purchase History"
      value="1160"
      valueclass="bg-green-100 p-2  text-green-400"
      description="NGN 376,221"
      @click="navigation('/retailerspurchasehistory')"
    />

    <!-- <menuitems
               iconclass="fas fa-user text-green-400"
               title="RDE List"
               value="75"
               valueclass="bg-green-100 p-2  text-green-400"
               description="Segun Ogunjobi,Kabir Kolewole and 73 others"
           /> -->

    <menuitems
      iconclass="fas fa-power-off text-red-400"
      title="Log Out"
      value=""
      valueclass="bg-green-100 p-2  text-green-400"
      description="General Overview,Account Managements..."
      @click="navigation('/login')"
    />
  </div>
</template>

<script>
import menuitems from "@/components/uicomponents/menus/components/menuitems";

export default {
  name: "RdeMenu",
  components: {
    menuitems: menuitems,
  },
  methods: {
    navigation: function (url) {
      this.$router.push(url);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style>
.img-menu {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://res.cloudinary.com/dcsensx8u/image/upload/v1645369586/Screenshot_2022-02-20_at_4.06.18_PM.png");
  background-size: cover;
}
</style>
