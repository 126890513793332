<template>
  <div class="bg-white p-5 flex flex-col space-y-4 shadow-2xl h-full">
    <img src="https://res.cloudinary.com/dcsensx8u/image/upload/v1644320869/Hotspot_logo_5.png" alt="Expresswifi" class="rounded">
    <router-link
        to="/retailer/overview/main"
        class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150">
             <i class="fas fa-home mr-3  text-base"></i> Home
    </router-link>

    <router-link
        to="/retailer/overview/inbox"
        class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150">
          <div v-if="total_unread>0">
              <i    class="fas fa-exclamation-circle mr-3  text-base inbox relative"></i> Notification ({{total_unread}} Unread)
            </div>
        <div v-else>
           <i
           class="fas fa-exclamation-circle  mr-3  text-base "></i> Notification
        </div>

    </router-link>



    <router-link
        to="/retailer/overview/profile"
        class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150">
      <i class="fas fa-user-circle mr-3  text-base"></i> Profile and Security
    </router-link>





    <router-link
        to="/retailer/overview/customers">
      <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-user-friends mr-3 text-base"></i>  Customer Sale</p>
    </router-link>

    <div class="flex flex-col  space-y-2">
      <p @click="show_wallet_submenu = !show_wallet_submenu"
         class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150 cursor-pointer">
            <i v-if="show_wallet_submenu"  class="fas fa-caret-down mr-3 text-lg"></i>
            <i v-else  class="fas fa-caret-up mr-3 text-lg"></i>
           Wallet Recharge
      </p>
      <transition name="list">
        <div class="flex flex-col p-2 ml-5 mr-10 w-max" v-if="show_wallet_submenu">
          <router-link to="/retailer/overview/topup">
            <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all animate__fadeInUp duration-150"> <i class="fas fa-shopping-cart mr-3 text-base"></i> Top Up</p>
          </router-link>

          <router-link to="/retailer/overview/topup/history">
            <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all animate__fadeInUp duration-150"> <i class="fas fa-chart-line  mr-3 text-base"></i> Sales History</p>
          </router-link>

          <router-link to="/retailer/overview/wallet_topup/history">
            <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all animate__fadeInUp duration-150"> <i class="fas fa-chart-line  mr-3 text-base"></i> Top Up History</p>
          </router-link>

        </div>
      </transition>
    </div>


<!--    salesreport-->

    <!-- <router-link
        to="/retailer/overview/salesreport">
      <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-coins mr-3 text-base"></i> Sales Report</p>
    </router-link> -->

    <router-link
        to="/retailer/overview/report">
      <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-bug mr-3 text-base"></i> Report Issue</p>
    </router-link>


    <!-- <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-globe mr-3 text-base"></i> Relocation </p> -->
<!--    <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-cogs mr-3 text-base"></i> Settings </p>-->


    <router-link
        to="/login">
      <p class="text-red-600 hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-power-off mr-3 text-base"></i> Log Out </p>
    </router-link>



  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "Menu",
  created(){
    let vm = this

    setInterval(() => {
        vm.$store.dispatch('retailers/action_retailer_total_unread_messages',{
                toast:this.$toast,
                router:this.$router
              });

         this.$forceUpdate()

      console.log("Menu Component")
    }, 120000);


    this.$store.dispatch('retailers/action_retailer_total_unread_messages',{
                toast:this.$toast,
                router:this.$router
              });
  },
  data(){
    return{
      show_wallet_submenu:false
    }
  },

    computed:{
        ...mapGetters({
          total_unread :'retailers/total_unread',
        })
  },
}
</script>

<style scoped>

.list-enter,
.list-leave-to {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}



.inbox::after{
  /*width: 10px;*/
  /*height: 10px;*/
  /*content: "";*/
  /*position: absolute;*/
  /*top:0;*/
  /*right:10;*/
  /*border-radius: 10px;*/
  /*background-color: red;*/

  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  right: -5px;
  top: 0px;
  background: hsl(358,62%,52%);
  z-index: 2;
  /*border: 2px solid var(--black-025);*/
  transition: top cubic-bezier(.165, .84, .44, 1) .15s;
}
</style>
