<template>

  <div class="flex flex-col lg:flex-row items-center lg:space-x-8 bg-inboxbg rounded p-5 shadow-xl border-2  hover:border-2 hover:border-express mt-5 cursor-pointer" @click="viewMessage">
    <div class="flex w-10 h-10 rounded-full bg-letterbg text-white text-center font-bold items-center justify-center">
      {{letter}}
    </div>
    <div class="flex flex-col justify-between w-full mt-5 lg:mt-0">
      <div class="text-center lg:text-left">

        <h1 class=" text-xl" :class="{'font-bold': status=='Unread' }">{{from}} </h1>
         <h1 class="text-letterbg"  :class="{'font-bold': status=='Unread' }">{{subject}} </h1>
        <p class="text-expressgray mt-3">
          {{ short_msg }}
        </p>
      </div>

      <div class="flex justify-evenly lg:justify-between w-full mt-14 text-letterbg">
        <p>{{ date }} </p>
        <p>{{ status }}</p>
      </div>

    </div>
  </div>


</template>

<script>
export default {
  name: "inboxCard",
  props:[
      "id",
      "letter",
      "subject",
      "from",
      "short_msg",
      "long_msg",
      "date",
      "status"
  ],
  emits:["viewMessage"],
  methods:{
    viewMessage: function(){
      //mark message as read
      this.$emit("viewMessage", this.id)
    }
  }
}
</script>

<style scoped>

</style>
