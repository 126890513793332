export default {
    customers(state){
        return state.customers
    },
    payment_history(state){
        return state.payment_history
    },
    customer_issues(state){
        return state.customer_issues
    },
    customer_list(state){
        return state.customer_list
    }


}
