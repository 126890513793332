<template>
    <div class="flex flex-col bg-white rounded-2xl shadow-2xl w-full md:p-5 p-0">
      <div class="flex flex-row space-x-4 items-center justify-center md:justify-between text-expressgray text-lg">

        <!-- <div class="flex items-center space-x-1 relative cursor-pointer border-2 hover:border-green-600 rounded p-3" @click="toggleFilters">
          <span>Filters  </span>
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd"></path></svg>
           <div class="w-52 rounded-2xl shadow-2xl p-0 absolute top-0 left-28 bg-white border-2"  v-if="showfilter">
                <ul class="flex flex-col space-y-4">
                  <li class="hover:bg-gray-600 hover:text-white w-full p-5 cursor-pointer rounded">Under Investigation</li>
                  <li class="hover:bg-gray-600 hover:text-white w-full p-5 cursor-pointer rounded">Closed</li>
                </ul>
           </div>

        </div> -->



<!--        NewIssue-->
        <router-link
            to="/report/newIssue">
          <button class="bg-green-600 text-white  hover:bg-green-500 transition-all ease-in duration-150 rounded px-3 py-2 mt-2 md:mt-0"
          >
            Report New Issue
          </button>
        </router-link>


      </div>


      <div v-if= "issues.data.length" class="flex flex-col space-y-3 w-full mt-20 text-expressgray  p-3 mb-5">
              <div v-for="issue in issues.data" :key="issue.id">
                  <div class="flex  items-center  justify-between  space-x-4 border-b-2 p-4">
                    <div class="flex items-center   space-x-4">
                      <svg class="w-6 h-6 fill-yellow" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 6a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 100 4v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2a2 2 0 100-4V6z"></path></svg>
                      <div class="div flex flex-col">
                          <p class="md:text-2xl sm:text-lg text-sm">{{issue.title}}</p>
                          <p class="md:text-sm text-xs">{{getHumanDate(issue.created_at) }}</p>
                      </div>
                     
                  </div>

          <div class="flex items-center space-x-4">
            
                <span 
                   v-if="issue.status === 'Under Investigation' "
                   class="rounded-2xl bg-yellow-600 text-white md:p-3 p-2 text-center md:text-sm text-xs">
                       {{issue.status}}
                </span>

                 <span 
                    v-else
                    class="rounded-2xl bg-green-600 text-white md:p-3 p-2 text-center md:text-sm text-xs">
                       {{ issue.status}}
                 </span>
          
      
            <router-link
                :to="generate_issue_url(issue.id)">
              <p class="border-2 p-3 cursor-pointer md:text-base text-xs"> Details </p>
            </router-link>

          </div>
        </div>

      </div>

      </div>
      <div v-else>
          <p class="text-center text-2xl">No issues found</p>
      </div>

        <div class="max-w-lg mx-auto my-12">
                <pagination :total-pages="totalPages"
                            :total="total"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :has-more-pages="hasMorePages"
                            @pagechanged="showMore">
                </pagination>
              </div>


    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'


export default {
  name: "ReportIssue",
  created(){

          this.totalPages = this.issues.to
          this.total = this.issues.total 
          this.perPage = this.issues.per_page
          this.currentPage = this.issues.current_page 
          this.hasMorePages = this.issues.last_page > this.issues.current_page



        this.$store.dispatch('issue/action_issues',{
            toast:this.$toast,
            router:this.$router
          });

          console.log('issues',this.issues)
  },
  data(){
    return {
      showfilter:false,
        totalPages: 1,
        total: 10,
        perPage: 5,
        currentPage :  1,
        hasMorePages: true,
        isLoading:false,
        showModel: false
    }
  },
   computed:{
        ...mapGetters({
          issues: 'issue/issues',
          // getUser: 'authentication/getUser',
        }),
       
  },
  methods: {
    toggleFilters: function (){
      this.showfilter = !this.showfilter
    },
     getHumanDate : function (date) {
                return moment(date, 'YYYY-MM-DD').fromNow(); 
                // .format('Do MMMM,YYYY');
     },
      generate_issue_url: function (topic_uid){
          return `/report/${topic_uid}/details`
        }
     
  }
}
</script>

<style scoped>

</style>
