<template>
  <div class="flex flex-col rounded w-full pt-4 px-1 min-h-screen space-y-6">
    <div>
      <h2
        class="text-xl text-center lg:pl-10 uppercase lg:text-left mt-5 font-semibold font-serif"
      >
        Retailer Purchase History
      </h2>
    </div>

    <div
      class="flex relative rounded border w-4/5 border-expressgray justify-between items-center mt-5 mx-auto"
    >
      <input
        type="text"
        class="w-full px-4 py-1 border-0 ml-2 outline-none"
        v-model.trim="retailer"
        placeholder="Search using phone number"
        @keyup.enter="getRetailerPurchaseHistory"
      />
      <i
        class="fas fa-search ml-1 p-2 text-lg hover:text-express hover:scale-100 cursor-pointer"
        @click="getRetailerPurchaseHistory"
      ></i>
    </div>

    <div
      v-if="!isLoading"
      class="flex flex-col rounded shadow-lg bg-white w-full mt-10 text-expressgray mb-5 h-full"
    >
      <div class="overflow-x-scroll">
        <table class="w-full">
          <tr class="border-b h-14 border-ndigo-100 text-center bg-indigo-50">
            <th class="p-6">Name</th>
            <th class="p-6">Business name</th>
            <th class="p-6">Mobile</th>
            <th class="p-6">Old Wallet Balance</th>
            <th class="p-6">New Wallet Balance</th>
            <th class="p-6">Recharge Amount</th>
            <th class="p-6">Currency</th>
            <th class="p-6">Payment Reference</th>
            <th class="p-6">Added By</th>
            <th class="p-6">Country</th>
            <th class="p-6">Date</th>
          </tr>
          <tbody v-if="retailer_purchase_history_list.length > 0">
            <tr
              class="border-b h-14 border-gray-600 text-center"
              v-for="(item, index) in retailer_purchase_history_list"
              :key="index"
            >
              <td class="p-6">{{ item.name }}</td>
              <td class="p-6">{{ item.business_name }}</td>
              <td class="p-6 capitalize">{{ item.phone }}</td>
              <td class="p-6">{{ item.old_balance }}</td>
              <td class="p-6">{{ item.new_balance }}</td>
              <td class="p-6">{{ item.amount }}</td>
              <td class="p-6">{{ item.currency }}</td>
              <td class="p-6">{{ item.reference }}</td>
              <td class="p-6 capitalize">{{ item.added_by }}</td>
              <td class="p-6">{{ item.country }}</td>
              <td class="p-6 capitalize whitespace-nowrap">
                {{ item.created_at }}
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <p class="whitespace-nowrap font-semibold text-base ml-2">
                No data available
              </p>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="mt-5 mx-5" v-else>
      <loading message="Loading, please wait"></loading>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const $toast = useToast();
const $router = useRouter();

const retailer = ref(null);
const isLoading = ref(false);

const retailer_purchase_history_list = computed(
  () => store.getters["admins/retailer_purchase_history"]
);

async function getRetailerPurchaseHistory() {
  if (!checkNumber(retailer.value).status) {
    $toast.info("Please provide valid phone number", {
      position: "top-right",
    });
    return;
  }
  isLoading.value = true;

  store.commit("admins/setRetailersPurchaseHistoryEmpty");

  await store.dispatch("admins/get_retailer_purchase_history", {
    phone: checkNumber(retailer.value).digit,
    toast: $toast,
    router: $router,
  });

  isLoading.value = false;
}

function checkNumber(num) {
  console.log(num);
  const phone = num.slice(-10);
  if (!isNaN(num) && num.length >= 10) {
    return {
      status: true,
      digit: Number(phone),
    };
  }
  return {
    status: false,
  };
}
</script>

<style></style>
