<template>
  <div class="flex flex-col p-1 md:p-5">
    <div class="flex flex-col text-xl bg-white p-5 shadow-2xl w-full" v-if="!loading">
      <h1 class="text-expressgray text-2xl mb-2 text-center font-semibold font-serif"> Report New Issue   </h1>

      <div class="flex p-4 space-x-5 mt-2 items-center">

        <select class="form-control p-4 w-full"  v-model="form.category">
          <option v-for="category in categories" :value="category" :key="category">{{category}}</option>
        </select>
<!--        <h1 class="text-red-600 text-4xl" >*</h1>-->
      </div>

      <div class="flex items-center mt-3 mb-5 space-x-4 p-4">
        <textarea class="border-2 p-4 w-full " cols="30" rows="10" v-model="form.description" placeholder="Description..."></textarea>
<!--        <h1 class="text-red-600 text-4xl" >*</h1>-->
      </div>


      <div class="flex items-center mt-3 mb-5 space-x-4 ">
        <button class="p-5 w-full" :class="disabled? 'bg-gray-400 text-white cursor-not-allowed':'bg-expressbutton text-white' " @click="report_new_customer_issue" :disabled="disabled"> Report </button>
      </div>


    </div>
    <div v-else class="mt-20">
      <loading message="Loading, please wait"></loading>
    </div>


  </div>


</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CustomerReport",
  created() {
    //console.log('user email', this.user.email)
  },
  data(){
    return {
      loading:false,
      disabled: true,
      categories:[
          'Select Category',
          'Internet/Connectivity',
          'Payment Issue',
          'Feedback/Recommendation',
          'Others'
      ],
      form:{
        category: 'Select Category',
        description: ''
      }
    }
  },
  watch: {
    form:{
      deep: true,      // detecting nested changes in objects
      immediate: true, // triggering the handler immediately with the current value
      handler(newValue) {
        this.disabled = !(newValue.category !== 'Select Category' && newValue.description.length > 10);
      }
    }

  },
  computed:{
    ...mapGetters({
      // customers_total :'retailers/customers_total',
      // wallet_balance: 'retailers/wallet_balance',
      // sales_volume: 'retailers/sales_volume',
      user: 'authentication/getUser',
      // customers: 'customers/customers'
    }),
  },
  methods:{
    report_new_customer_issue: function(){
      console.log("report_new_customer_issue triggered")
      this.loading = true
      let vm = this
      vm.$store.dispatch('customers/action_customers_report_issue',{
        message : vm.form.description,
        category:vm.form.category,
        customer_email:vm.user.email,
        toast:this.$toast,
        router:this.$router
      });

      setTimeout(function (){
        //
        vm.form.description  = ''
        vm.form.category = 'Select Category'
        vm.loading = false

      },3000)
    }
  }
}
</script>

<style scoped>

</style>
