import mutations from './mutations'
import getters from './getters'
import actions from './actions'

export default {
    namespaced: true,
    state(){
        return {
            // enviroment: 'development',
            enviroment: 'production',
            customers_total: "",
            wallet_balance:"",
            wallet_history:[],
            wallet_topup_history: [],
            currency:"",
            sales_volume:"",
            total_unread:"",
            notification_messages:[],
            customers:[],
            retailers:[],
            computed_retailers:[]
        }
    },
    mutations,
    actions,
    getters
}
