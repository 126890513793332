<template>
  <div class="bg-white flex flex-col p-10  shadow-2xl mx-20">
       <h1 class="text-2xl mb-5 text-left">Showing results for: {{search_value}}</h1>


<!--    'retailer_id',-->
<!--    'staff_email',-->
<!--    'name',-->
<!--    'business_name',-->
<!--    'email',-->
<!--    'phone',-->
<!--    'address'-->

<!--    showAddCustomerAccount-->
<!--    add_customer_account_model-->

    <add_customer_account_model
        v-if="showAddCustomerAccount"
        :selected_retailer="selected_retailer"
        @completeTransaction_add_customer_account="completeTransaction_add_customer_account"
        @cancelTransaction_add_customer_account="cancelTransaction_add_customer_account"

    />


        <edit_retailer_model
            v-if="showRetailerEditModal"
            :selected_retailer="selected_retailer"
            @completeTransaction="completeTransaction"
            @cancelTransaction="cancelTransaction"

        />

    <delete_customer_account_model
        v-if="showDeleteCustomerAccount"
        :selected_customer="selected_customer"
        @completeTransaction_delete_customer_account="completeTransaction_delete_customer_account"
        @cancelTransaction_delete_customer_account="cancelTransaction_delete_customer_account"/>

       <div class="overflow-x-auto " v-if="searched_personnel.length>0">
         <table class="w-full text-left mt-8 p-2">
           <thead  class="border-2 border-gray-200 ">

           <tr class="  ">
             <th class="p-5  border-2 border-gray-200">Business Name</th>
             <th class="p-5  border-2 border-gray-200">Name</th>
             <th class="p-5  border-2 border-gray-200">Email</th>
             <th class="p-5  border-2 border-gray-200">Phone</th>
             <th class="p-5  border-2 border-gray-200">Account Type</th>
             <th class="p-5  border-2 border-gray-200">Wallet Balance</th>
             <th class="p-5  border-2 border-gray-200">Address</th>
             <th class="p-5  border-2 border-gray-200">Status </th>
             <th class="p-5  border-2 border-gray-200">Country</th>
             <th class="p-5  border-2 border-gray-200">State</th>
             <th class="p-5  border-2 border-gray-200">Zone</th>
             <th class="p-5  border-2 border-gray-200">City </th>
             <th class="p-5  border-2 border-gray-200">Latitude</th>
             <th class="p-5  border-2 border-gray-200">Longitude </th>
             <th class="p-5  border-2 border-gray-200">Edit </th>
             <th class="p-5  border-2 border-gray-200">Add Customer Account </th>
             <th class="p-5  border-2 border-gray-200">Delete Customer Account </th>

           </tr>
           </thead>
           <tbody>

             <tr v-for="person in searched_personnel" :key="person.id">
               <td  class="p-5  border-2 border-gray-200 capitalize">{{ person.business_name }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize">{{ person.name }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize">{{  person.email }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize">{{  person.phone }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize">{{  person.type_name }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize">{{  person.wallet }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize">{{  person.address }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize">{{  person.status }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize">{{  person.country }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize">{{  person.state }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize">{{  person.zone }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize">{{  person.city }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize">{{  person.latitude }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize">{{  person.longitude }}</td>
               <td  class="p-5  border-2 border-gray-200 capitalize"> <button v-if="isAdmin &&  person.type_name === 'retailer' " @click="select_retailer(person)" class="bg-yellow-500 text-white  px-4 py-2  rounded"> Edit </button> <div v-else> <i class="fas fa-ban"></i> </div> </td>
               <td  class="p-5  border-2 border-gray-200 capitalize"> <button v-if="isAdmin &&  person.type_name === 'retailer' " @click="add_customer_account(person)" class="bg-green-500 text-white  px-4 py-2  rounded"> Add  </button> <div v-else> <i class="fas fa-ban"></i> </div> </td>
               <td  class="p-5  border-2 border-gray-200 capitalize"> <button v-if="isAdmin &&  person.type_name === 'customer' " @click="delete_customer_account(person)" class="bg-red-500 text-white  px-4 py-2  rounded"> Delete  </button> <div v-else> <i class="fas fa-ban"></i> </div> </td>

             </tr>

           </tbody>
         </table>

       </div>
       <div v-else>
         <h1>No Results found</h1>
       </div>
<!--      <table-lite-->
<!--          :has-checkbox="false"-->
<!--          :is-loading="loading"-->
<!--          :is-re-search="isReSearch"-->
<!--          :columns="columns"-->
<!--          :rows="searched_personnel"-->
<!--          :total="searched_personnel.length"-->
<!--      ></table-lite>-->

  </div>
</template>

<script>

import edit_retailer_model from "@/components/uicomponents/models/edit_retailer_model";
import add_customer_account_model from "@/components/uicomponents/models/add_customer_account_model";
import delete_customer_account_model from "@/components/uicomponents/models/delete_customer_account_model";
import {mapGetters} from "vuex";

export default {
 name: "SearchView",
  components: {
    edit_retailer_model,
    add_customer_account_model,
    delete_customer_account_model
  },
  created(){
    console.log("searched_personnel",this.searched_personnel)
  },
  props:["search_value"],
  data(){
    return {
      loading:false,
      isReSearch:false,
      showRetailerEditModal: false,
      showAddCustomerAccount:false,
      showDeleteCustomerAccount: false,
      selected_retailer:{},
      selected_customer:{},
      columns: [
        {
          label: "Name",
          field: "name",
          width: "5%",
          sortable: false,
          isKey: false,
        },
        {
          label: "Email",
          field: "email",
          width: "10%",
          sortable: false,
        },
        {
          label: "Mobile",
          field: "phone",
          width: "5%",
          sortable: false,
        },
        {
          label: "Business Name",
          field: "business_name",
          width: "5%",
          sortable: false,
        },
        {
          label: "Wallet Balance",
          field: "wallet",
          width: "15%",
          sortable: false,
        },
        {
          label: "Account Type",
          field: "type_name",
          width: "5%",
          sortable: false,
        },
        {
          label: "Account Status",
          field: "status",
          width: "5%",
          sortable: false,
        },
        {
          label: "Account Type",
          field: "type_name",
          width: "5%",
          sortable: false,
        },
        {
          label: "Registered",
          field: "created_at",
          width: "5%",
          sortable: false,
        },
        {
          label: "Address",
          field: "address",
          width: "40%",
          sortable: false,
        },

      ],
      totalRecordCount: 2,
      sortable: {
        order: "id,name",
        sort: "asc",
      },

    }
 },
  methods:{
   reload_page(){
     this.$forceUpdate();
   },
   add_customer_account(retailer){
     this.selected_retailer = retailer
     this.showAddCustomerAccount  = true
   },
    delete_customer_account(customer){
      this.selected_customer = customer
      this.showDeleteCustomerAccount  = true
    },
   select_retailer(retaier){

     this.selected_retailer = retaier
     this.showRetailerEditModal  = true

   },

    toogleShowEditModal(){
      this.showRetailerEditModal  = true
    },
    completeTransaction_delete_customer_account(data){
      console.log("completeTransaction_delete_customer_account", data)
      this.$store.dispatch('retailers/action_delete_customer_account',{
        data:data,
        toast:this.$toast,
        router:this.$router
      });
      this.showDeleteCustomerAccount = false
      this.reload_page()
    },
    cancelTransaction_delete_customer_account(){
      this.showDeleteCustomerAccount = false
    },

    completeTransaction_add_customer_account(data){
      console.log("completeTransaction_add_customer_account", data)
      this.$store.dispatch('retailers/action_add_customer_account',{
        data:data,
        toast:this.$toast,
        router:this.$router
      });


      this.showAddCustomerAccount = false
    },
    cancelTransaction_add_customer_account(){
      this.showAddCustomerAccount = false
    },
    completeTransaction(data){
     // console.log("retailer edit transaction data",data)
      this.$store.dispatch('retailers/action_update_retailer_information',{
        data:data,
        toast:this.$toast,
        router:this.$router
      });

      this.showRetailerEditModal = false
    },
    cancelTransaction(){
      this.showRetailerEditModal = false
    }
  },
  computed:{
   isAdmin: function (){
     let checkadmin = false
       this.user.account_types.map(o=>{
        if(o.name === "super_administrator"){
          checkadmin = true
        }
     })
     return checkadmin
   },
    ...mapGetters({
      user: 'authentication/getUser',
      searched_personnel: 'admins/searched_personnel'
      // products: 'products/products'
    }),
  }
}
</script>

<style>

</style>
