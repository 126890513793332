<template>
  <div class="bg-white flex flex-col px-4 space-y-4 h-full">
    <img src="https://res.cloudinary.com/dcsensx8u/image/upload/v1644320869/Hotspot_logo_5.png" alt="Expresswifi" class="w-60">
    <router-link
        to="/customer/overview/main"
        class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150">
             <i class="fas fa-home mr-3  text-base"></i> Home
    </router-link>

    <!-- <router-link
        to="/retailer/overview/inbox"
        class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150">
          <div v-if="total_unread>0">
              <i    class="fas fa-exclamation-circle mr-3  text-base inbox relative"></i> Notification ({{total_unread}} Unread)
            </div>
        <div v-else>
           <i
           class="fas fa-exclamation-circle  mr-3  text-base "></i> Notification
        </div>

    </router-link> -->



    <router-link
        to="/customer/overview/products"
        class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150">
      <i class="fas fa-shopping-bag mr-3  text-base"></i> Products
    </router-link>

    <router-link
        to="/customer/overview/history"
        class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150">
      <i class="fas fa-history mr-3  text-base"></i> Purchase History
    </router-link>



    <router-link
        to="/customer/overview/retailers">
      <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-user-friends mr-3 text-base"></i>  Find Retailer Store</p>
    </router-link>





     <div class="flex flex-col  space-y-2">
      <p @click="show_wallet_submenu = !show_wallet_submenu"
         class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150 cursor-pointer">
            <i v-if="show_wallet_submenu"  class="fas fa-caret-down mr-3 text-lg"></i>
            <i v-else  class="fas fa-caret-up mr-3 text-lg"></i>
           Troubleshooting
      </p>
      <transition name="list">
        <div class="flex flex-col p-2 ml-5 mr-10 w-max " v-show="show_wallet_submenu">
          <router-link
              to="/customer/overview/report">
            <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-bug mr-3 text-base"></i>  Report New Issue </p>
          </router-link>

          <router-link
              to="/customer/overview/report/view">
            <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-file-contract mr-3 text-base"></i>  Track Reported Issues </p>
          </router-link>

        </div>
      </transition>
    </div>


<!--    salesreport-->

    <!-- <router-link
        to="/retailer/overview/salesreport">
      <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-coins mr-3 text-base"></i> Sales Report</p>
    </router-link> -->

     <router-link
        to="/customer/overview/settings">
      <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-cogs mr-3 text-base"></i> Change Password</p>
    </router-link>


    <!-- <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-globe mr-3 text-base"></i> Relocation </p> -->
<!--    <p class="hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-cogs mr-3 text-base"></i> Change Password </p>-->


    <router-link
        to="/login">
      <p class="text-red-600 hover:border-b-2 border-expressgray hover:text-white hover:bg-blue-500 rounded p-3 transition-all ease-linear duration-150"><i class="fas fa-power-off mr-3 text-base"></i> Log Out </p>
    </router-link>



  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "Menu",
  created(){
    this.$store.dispatch('retailers/action_retailer_total_unread_messages',{
                toast:this.$toast,
                router:this.$router
              });
  },
  data(){
    return{
      show_wallet_submenu:false
    }
  },

    computed:{
        ...mapGetters({
          total_unread :'retailers/total_unread',
        }),

  },
}
</script>

<style scoped>

.list-enter,
.list-leave-to {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}



.inbox::after{
  /*width: 10px;*/
  /*height: 10px;*/
  /*content: "";*/
  /*position: absolute;*/
  /*top:0;*/
  /*right:10;*/
  /*border-radius: 10px;*/
  /*background-color: red;*/

  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  right: -5px;
  top: 0px;
  background: hsl(358,62%,52%);
  z-index: 2;
  /*border: 2px solid var(--black-025);*/
  transition: top cubic-bezier(.165, .84, .44, 1) .15s;
}
</style>
