<template>
  <DataTransfer :onTabChange="onTabChange">
    <template v-slot:custTocust>
      <div class="w-full bg-white shadow-lg px-5 pt-8 pb-10">
        <h1
          class="text-sm sm:text-lg text-center lg:pl-10 uppercase lg:text-left mt-5 font-semibold font-serif flex items-center"
        >
          Customer
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-5 mx-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
          Customer Transfer
        </h1>

        <form action="" class="mt-8 w-full flex flex-col items-center">
          <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-5 w-full">
            <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
              <label for="from" class="font-semibold mb-2 sm:mb-0">From:</label>

              <input
                type="text"
                name="from"
                id="from"
                v-model="fromMac"
                placeholder="Mac ID"
                class="outline-none bg-white h-10 shadow-md border rounded-lg w-full px-3"
              />
            </div>

            <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
              <label for="to" class="font-semibold mb-2 sm:mb-0">To:</label>

              <input
                type="text"
                name="to"
                id="to"
                v-model="toMac"
                placeholder="Mac ID"
                class="outline-none bg-white h-10 shadow-md border rounded-lg w-full px-3"
              />
            </div>
            <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
              <label for="currency" class="font-semibold mb-2 sm:mb-0"
                >Currency:</label
              >

              <select
                name="currency"
                id="currency"
                v-model="currency"
                class="outline-none bg-white h-10 shadow-md border rounded-lg w-full"
              >
                <option value="NGN">Naira</option>
                <option value="GHC">Cedis</option>
              </select>
            </div>
            <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
              <label for="plan" class="font-semibold mb-2 sm:mb-0">Plan:</label>

              <select
                name="plan"
                id="plan"
                v-model="selected_plan"
                class="outline-none bg-white h-10 shadow-md border rounded-lg w-full"
              >
                <option v-if="isLoading" value="">Loading...</option>
                <option
                  v-else
                  v-for="product in [...plans]"
                  v-bind:value="[product.uid, product.amount, product.name]"
                  :key="product.uid"
                  class="p-2 text-expressgray"
                >
                  {{ product.name }}
                </option>
              </select>
            </div>
          </div>

          <input
            type="submit"
            name="transfer"
            id="transfer"
            @click.prevent="submit"
            value="Transfer"
            :disabled="!buttonDisabled"
            class="mt-11 rounded-lg saturate-50 text-white h-11 w-56 mx-auto"
            :class="
              !buttonDisabled
                ? 'bg-green-400 cursor-not-allowed'
                : 'bg-green-600 cursor-pointer'
            "
          />
        </form>

        <div v-if="isTransferLoading" class="mt-5 mx-3">
          <loading message=""></loading>
        </div>
      </div>
    </template>

    <template v-slot:custToretl>
      <div class="w-full bg-white shadow-lg px-5 pt-8 pb-10">
        <h1
          class="text-sm sm:text-lg text-center lg:pl-10 uppercase lg:text-left mt-5 font-semibold font-serif flex items-center"
        >
          Customer
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-5 mx-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
          Retailer Transfer
        </h1>

        <form action="" class="mt-8 w-full flex flex-col items-center">
          <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-5 w-full">
            <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
              <label for="from" class="font-semibold mb-2 sm:mb-0">From:</label>

              <input
                type="text"
                name="from"
                id="from"
                v-model="fromMac"
                placeholder="Customer Mac ID"
                class="outline-none bg-white h-10 shadow-md border rounded-lg w-full px-3"
              />
            </div>

            <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
              <label for="toPhone" class="font-semibold mb-2 sm:mb-0"
                >To:</label
              >

              <input
                type="text"
                name="toPhone"
                id="toPhone"
                v-model="toPhone"
                placeholder="Retailer Phone number"
                class="outline-none bg-white h-10 shadow-md border rounded-lg w-full px-3"
              />
            </div>
            <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
              <label for="currency" class="font-semibold mb-2 sm:mb-0"
                >Currency:</label
              >

              <select
                name="currency"
                id="currency"
                v-model="currency"
                class="outline-none bg-white h-10 shadow-md border rounded-lg w-full"
              >
                <option value="NGN">Naira</option>
                <option value="GHC">Cedis</option>
              </select>
            </div>
            <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
              <label for="plan" class="font-semibold mb-2 sm:mb-0">Plan:</label>

              <select
                name="plan"
                id="plan"
                v-model="selected_plan"
                class="outline-none bg-white h-10 shadow-md border rounded-lg w-full"
              >
                <option v-if="isLoading" value="">Loading...</option>
                <option
                  v-else
                  v-for="product in [...plans]"
                  v-bind:value="[product.uid, product.amount, product.name]"
                  :key="product.uid"
                  class="p-2 text-expressgray"
                >
                  {{ product.name }}
                </option>
              </select>
            </div>
          </div>

          <input
            type="submit"
            name="transfer"
            id="transfer"
            @click.prevent="submitCustToRetailer"
            value="Transfer"
            :disabled="!buttonDisabledCustToRetailer"
            class="mt-11 rounded-lg saturate-50 text-white h-11 w-56 mx-auto"
            :class="
              !buttonDisabledCustToRetailer
                ? 'bg-green-400 cursor-not-allowed'
                : 'bg-green-600 cursor-pointer'
            "
          />
        </form>

        <div v-if="isTransferCustToRetlLoading" class="mt-5 mx-3">
          <loading message=""></loading>
        </div>
      </div>
    </template>
    <template v-slot:retlToretl>
      <div class="w-full bg-white shadow-lg px-5 pt-8 pb-10">
        <h1
          class="text-sm sm:text-lg text-center lg:pl-10 uppercase lg:text-left mt-5 font-semibold font-serif flex items-center"
        >
          Retailer
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-5 mx-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
          Retailer Transfer
        </h1>

        <form action="" class="mt-8 w-full flex flex-col items-center">
          <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-5 w-full">
            <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
              <label for="fromPhone" class="font-semibold mb-2 sm:mb-0"
                >From:</label
              >

              <input
                type="text"
                name="fromPhone"
                id="fromPhone"
                v-model="fromPhone"
                placeholder="Retailer Phone number"
                class="outline-none bg-white h-10 shadow-md border rounded-lg w-full px-3"
              />
            </div>

            <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
              <label for="toPhone" class="font-semibold mb-2 sm:mb-0"
                >To:</label
              >

              <input
                type="text"
                name="toPhone"
                id="toPhone"
                v-model="toPhone"
                placeholder="Retailer Phone number"
                class="outline-none bg-white h-10 shadow-md border rounded-lg w-full px-3"
              />
            </div>

            <div class="flex flex-col md:flex-row md:items-center md:space-x-4">
              <label for="to" class="font-semibold mb-2 sm:mb-0">Amount:</label>

              <input
                type="number"
                name="amount"
                id="amount"
                v-model="retailerAmount"
                placeholder="Amount to transfer"
                class="outline-none bg-white h-10 shadow-md border rounded-lg w-full px-3"
              />
            </div>
          </div>

          <input
            type="submit"
            name="transfer"
            id="transfer"
            @click.prevent="submitRetailerToRetailer"
            value="Transfer"
            :disabled="!buttonDisabledRetlToRetailer"
            class="mt-11 rounded-lg saturate-50 text-white h-11 w-56 mx-auto"
            :class="
              !buttonDisabledRetlToRetailer
                ? 'bg-green-400 cursor-not-allowed'
                : 'bg-green-600 cursor-pointer'
            "
          />
        </form>

        <div v-if="isTransferRetlToRetlLoading" class="mt-5 mx-3">
          <loading message=""></loading>
        </div>
      </div>
    </template>
  </DataTransfer>
</template>

<script setup>
import { onMounted, watch } from "vue";
import { computed, ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { isValidMACAddress, checkDataPlan } from "@/utils/globalFunctions.js";
import DataTransfer from "../rdeuicomponent/DataTransferTab.vue";

const store = useStore();
const $toast = useToast();
const $router = useRouter();

const currency = ref("NGN");
const selected_plan = ref([]);
const isLoading = ref(false);
const isTransferLoading = ref(false);
const isTransferCustToRetlLoading = ref(false);
const isTransferRetlToRetlLoading = ref(false);
const fromMac = ref("");
const toMac = ref("");
const plans = computed(() => store.getters["products/products"]);

const toPhone = ref("");
const fromPhone = ref("");
const retailerAmount = ref();

const buttonDisabled = computed(() => {
  return (
    (currency.value === "NGN" || currency.value === "GHC") &&
    fromMac.value.length === 17 &&
    toMac.value.length === 17 &&
    selected_plan.value.length > 0
  );
});

const buttonDisabledCustToRetailer = computed(() => {
  return (
    (currency.value === "NGN" || currency.value === "GHC") &&
    fromMac.value.length === 17 &&
    toPhone.value.length > 10 &&
    selected_plan.value.length > 0
  );
});

const buttonDisabledRetlToRetailer = computed(() => {
  return (
    fromPhone.value.length > 10 &&
    toPhone.value.length > 10 &&
    retailerAmount.value > 0
  );
});
onMounted(async () => {
  await fetchPlans(currency.value);
});

watch(currency, async (newCurrency) => {
  selected_plan.value = [];
  await fetchPlans(newCurrency);
});

const onTabChange = () => {
  toMac.value = "";
  fromMac.value = "";
  toPhone.value = "";
  fromPhone.value = "";
  selected_plan.value = [];
  retailerAmount.value = null;
};

async function fetchPlans(curr) {
  isLoading.value = true;

  await store.dispatch("products/action_products", {
    currency: curr,
    toast: $toast,
    router: $router,
  });

  isLoading.value = false;
}

const submit = async () => {
  if (!isValidMACAddress(fromMac.value) && !isValidMACAddress(toMac.value)) {
    $toast.error("Please provide valid Mac ID", {
      position: "top-right",
      duration: 4500,
    });
    return;
  }

  if (fromMac.value.toUpperCase() === toMac.value.toUpperCase()) {
    $toast.error("From and to Mac id's cannot be the same", {
      position: "top-right",
      duration: 4500,
    });
    return;
  }

  isTransferLoading.value = true;

  const amount = parseFloat(selected_plan.value[1]);
  const { duration, datapack } = checkDataPlan(amount);

  await store.dispatch("admins/transferCustomerData", {
    duration,
    datapack,
    from: fromMac.value,
    to: toMac.value,
    toast: $toast,
    router: $router,
  });
  isTransferLoading.value = false;

  toMac.value = "";
};
const submitCustToRetailer = async () => {
  if (!isValidMACAddress(fromMac.value)) {
    $toast.error("Please provide valid Mac ID", {
      position: "top-right",
      duration: 4500,
    });
    return;
  }
  if (toPhone.value < 10) {
    $toast.error("Please provide valid Phone number", {
      position: "top-right",
      duration: 4500,
    });
    return;
  }

  isTransferCustToRetlLoading.value = true;

  const amount = parseFloat(selected_plan.value[1]);

  const { duration, datapack } = checkDataPlan(amount);

  await store.dispatch("admins/transferCreditFromCustomerToRetailer", {
    duration: duration,
    datapack: datapack,
    mac: fromMac.value,
    phone: toPhone.value,
    amount: amount,
    toast: $toast,
    router: $router,
  });

  isTransferCustToRetlLoading.value = false;

  toPhone.value = "";
};

const submitRetailerToRetailer = async () => {
  if (toPhone.value < 10 && fromPhone.value < 10) {
    $toast.error("Please provide valid Phone number", {
      position: "top-right",
      duration: 4500,
    });
    return;
  }

  isTransferRetlToRetlLoading.value = true;

  await store.dispatch("admins/transferCreditFromRetailerToRetailer", {
    fromPhone: fromPhone.value,
    toPhone: toPhone.value,
    amount: retailerAmount.value,
    toast: $toast,
    router: $router,
  });

  isTransferRetlToRetlLoading.value = false;

  toPhone.value = "";
};
</script>

<style></style>
