<template>
  <div class=" w-full p-5 mx-5">
<!--    <input class="mb-5 w-full border-2 rounded-2xl p-4" type="text" v-model="search" placeholder="Search Table">-->

    <table class="w-full p-5 ">
      <tr>
        <th  class="border-2 p-2 text-left" v-for="(column, index) in columns" :key="index"  @click="sortRecords(index)">
          {{column}}
        </th>
      </tr>
      <tbody>
         <tr  v-for="(val , i) in rows" :key="i">
        <td class="p-5 border-2" > {{val["id"]}} </td>
        <td class="p-5 border-2"> {{val["full_name"]}} </td>
        <td class="p-5 border-2"> {{val["email"]}} </td>
        <td class="p-5 border-2"> {{val["phone"]}} </td>
        <td class="p-5 border-2"> {{ val['usage'][0]['username']}} </td>
        <td class="p-5 border-2"> {{ val['usage'][0]['total_upload_mb']}}</td>
        <td class="p-5 border-2"> {{ val['usage'][0]['total_download_mb']}}</td>
        <td class="p-5 border-2"> remaining balance </td>


      </tr>
      </tbody>
    </table>
  </div>
</template>



<script>


export default {
  name:"UsersListTable",
  props: ['rowRaws'],
  created() {
   // console.log('customer_list', this.rowRaws)
  },
  mounted() {
    this.rows =  [...this.rowRaws]
  },
  data(){
    return {
      search:'',
      columns:[
       'ID' ,'Full-Name',	'Email'	, 'Mobile',	'Device Mac', 'Total Upload', 'Total Download', 'Remaining Balance'
      ],
      row:[],
      rows:[],
      sortIndex: null,
      sortDirection: null

    }
  },
  watch:{
    search: function (newvalue, oldvalue){
      // console.log(newvalue,oldvalue)
      this.onSearch()
    }
  },
  methods: {

    sortRecords: function (index){
      if(this.sortDirection  ==  null){
        this.sortDirection = 'asc'
      }
      this.sortIndex = index
      this.rows = this.rows.sort(
          (rowA,rowB)=> {
            if(typeof (rowA[index]) == 'number' || typeof (rowB[index]) == 'number' ){
              // console.log('number sortting')
              if(this.sortDirection === 'asc' || this.sortDirection == null){
                this.sortDirection = 'desc'
                return rowA[index] - rowB[index]
              }else{
                this.sortDirection = 'asc'
                return  rowB[index] - rowA[index]
              }
              // return rowA[index].localeCompare(rowB[index], undefined, {'numeric': true})
            }else{
              return rowA[index].localeCompare(rowB[index])
            }

          }
      )
    },
    onSearch: function (){
      let term = this.search
      let temp = this.rowRaws
      this.rows = temp.filter(
          row => row.join(" ").includes(term)
      )
    }
  }
}

</script>
