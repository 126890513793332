<template>
  <div class="flex flex-col items-center justify-center ">
    <ul class="loading">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
    <p class="text-center">{{message}}</p>
  </div>


</template>

<script>
export default {
  name: "loading",
  props:['message']
}
</script>

<style scoped>
*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #fafafa;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.loading {
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*margin: -75px 0 0 -35px;*/
  width: 70px;
  /*height: 150px;*/
}

@keyframes loading {
  0%, 100% {
    height: 10px;
    margin-top: 25px;
  }

  50%{
    height: 50px;
    margin-top: 0px;
  }
}

li {
  background-color: #3C9FA9;
  width: 10px;
  height: 10px;
  float: left;
  margin-right: 5px;
  box-shadow: 0px 100px 20px rgba(0, 0, 0, 0.2);
  animation: loading 1s ease-in-out infinite;
}

li:nth-child(1) {
  animation-delay: 0s;
}

li:nth-child(2) {
  animation-delay: -0.2s;
}

li:nth-child(3) {
  animation-delay: -0.4s;
}

li:nth-child(4) {
  animation-delay: -0.6s;
}

li:nth-child(5) {
  margin-right: 0;
  animation-delay: -0.8s;
}
</style>
