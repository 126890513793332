<template>
 <div class="flex flex-col w-full p-5">
   <div class="flex flex-row space-x-4 items-center justify-between text-expressgray text-lg">
     <router-link to="/administrator/overview">
       <div class="space-x-1 flex items-center cursor-pointer text-xl border-2 rounded-xl bg-green-600 p-3  text-white hover:bg-green-700">
         <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6.707 4.879A3 3 0 018.828 4H15a3 3 0 013 3v6a3 3 0 01-3 3H8.828a3 3 0 01-2.12-.879l-4.415-4.414a1 1 0 010-1.414l4.414-4.414zm4 2.414a1 1 0 00-1.414 1.414L10.586 10l-1.293 1.293a1 1 0 101.414 1.414L12 11.414l1.293 1.293a1 1 0 001.414-1.414L13.414 10l1.293-1.293a1 1 0 00-1.414-1.414L12 8.586l-1.293-1.293z" clip-rule="evenodd"></path></svg>
         <p class="ml-3 ">Back</p>
       </div>
     </router-link>
   </div>

   <div class="mt-10 flex flex-col text-expressgray text-lg p-10 leading-normal bg-white rounded-2xl shadow-2xl 	">
      <div class="flex justify-between space-x-4 items-center py-4">
        <h2 class="block text-3xl text-black">{{specific_issue.support.title}}</h2>

        <span v-if="specific_issue.support.status == 'Under Investigation' " class=" rounded-2xl  bg-yellow-600  text-white p-3 text-center text-xs"> {{specific_issue.support.status}} </span>
         <span v-else class=" rounded-2xl  bg-green-600  text-white p-3 text-center text-xs"> {{specific_issue.support.status}} </span>

      </div>
     <div >
       <h4 class="text-lg mb-10 text-black">Created By [{{ specific_issue.creator.name}}, +{{specific_issue.creator.phone}} ] - {{ getHumanDate(specific_issue.support.created_at) }} </h4>
     </div>
      <h3 class="leadingx7 text-xl  text-black">
       {{ specific_issue.support.message}}

       </h3>

     <div class="flex space-x-4 mt-10  items-center">

       Tags:  <span class="border-b-2    ml-4  border-gray-300 capitalize">{{ specific_issue.support.category}}</span>
     </div>

     <div class="flex space-x-4 mt-1  items-center">


       <div class="flex flex-col mt-6 mb-3">
           Attachments
           <div class="flex space-x-4" v-if="specific_issue.attachments.length>0">
               <span   class="border-b-2 cursor-pointer border-gray-300" v-for=" attachment , index in specific_issue.attachments" :key="index">
                    <a :href="attachment.path" target="_blank">View Attachment</a>
               </span>
           </div>
       </div>

     </div>

   </div>

<!--   view comments-->


   <div v-if="support_comments.length>0" class="flex flex-col w-full shadow-2xl p-5 border-2 rounded-2xl mt-5 text-expressgray mb-10">
     <div class="flex justify-between w-full items-center ">
       <p class="font-bold text-xl mb-5">Comments</p>
     </div>

      <div v-for="(item, index) in support_comments" :key="index">
        <div class="mt-5 flex mb-5 w-full " >
          <img class="w-8 h-8 object-cover" src="https://res.cloudinary.com/dcsensx8u/image/upload/v1643932184/Screenshot_2022-02-04_at_12.49.36_AM.png" >
            <div class="flex flex-col ml-4">
              <p class="font-semibold mb-3 text-xl">

                {{item.creator.name}}
                </p>
                 <p> {{ item.message }}</p>

         <p class="text-sm mt-4">Posted: {{ getHumanDate(item.created_at) }}</p>


       </div>
     </div>

    </div>

   </div>


<!--   Create a comment -->

   <div v-if=" specific_issue.support.category !== 'Closed' " class="flex flex-col w-full shadow-2xl p-5 border-2 rounded-2xl mt-5 text-expressgray mb-10">

     <div class="flex justify-between w-full items-center ">
        <p class="font-bold">Write a Comment</p>
       <div class="flex space-x-4 items-center ">
         <p>Status</p>
         <select class="border-2 rounded-2xl p-3" v-model="form.status">
           <option value="Under Investigation">Under Investigation (No Status Changed)</option>
           <option value="Closed">Closed</option>
         </select>
       </div>
     </div>




     <div class="flex  flex-col w-full mt-5 ">
      <div class="flex justify-between w-full items-center">
        <!-- <img class="w-8 h-8 object-cover" src="https://res.cloudinary.com/dcsensx8u/image/upload/v1643932184/Screenshot_2022-02-04_at_12.49.36_AM.png" > -->
        <textarea class="flex-1 rounded border-2 border-blue-600 p-3 ml-3" name="comment" id="comment_id" cols="30" rows="10" placeholder="Write a comment..." v-model="form.message"></textarea>
      </div>

       <div class="flex space-x-4 mt-6 justify-between">
         <!-- <div class="flex space-x-4 p-3 rounded bg-gray-200  ">
           <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd"></path></svg>
           Add Attachment
         </div> -->

      <loading v-if="isLoading" message="Loading, please wait"></loading>
      <div v-else class="flex space-x-4 p-3 rounded bg-green-600 text-white " @click="create_comment">
           Submit
         </div>

       </div>

     </div>




   </div>



 </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: "AdminReportDetails",
  created(){
    if(this.id !== ''){


      this.$store.dispatch('issue/action_fetch_specific_topic',{
            id: this.id,
            toast:this.$toast,
            router:this.$router
          });

      this.$store.dispatch('issue/action_fetch_specific_topic_comments',{
                  id: this.id,
                  toast:this.$toast,
                  router:this.$router
                });

               //  console.log('specific_issue', this.specific_issue);
               console.log('support_comments', this.support_comments);

    }else{
     // this.$router.replace('/login')
      console.log('ignore')
    }
  },
  props:['id'],
  data(){
    return{
      isLoading: false,
      form:{
        status:'Under Investigation',
        message: '',


      }

    }
  },
  methods:{
    uuid: ()=>{
        const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
        const xAndYOnly = /[xy]/g;

        return template.replace(xAndYOnly, (character) => {
            const randomNo =  Math.floor(Math.random() * 16);
            const newValue = character === 'x' ? randomNo : (randomNo & 0x3) | 0x8;

            return newValue.toString(16);
        });
    },
    getHumanDate : function (date) {
                    return moment(date, 'YYYY-MM-DD').fromNow();
                    // .format('Do MMMM,YYYY');
                },
    create_comment: function(){


      // Upload comments here

      if(this.form.message == ''){
        this.$toast.error("Empty comment body")
      }else{
        this.isLoading = true;
        const message = this.form.message
        const comment_uid = this.uuid()
        const support_id = this.specific_issue.support.id
        const category = this.specific_issue.support.category
        const status = this.form.status

      //  console.log('status', status)

          this.$store.dispatch('issue/action_add_comment_to_topic',{
                  comment_uid: comment_uid,
                  support_id: support_id,
                  message: message,
                  status: status,
                  category: category,
                  toast:this.$toast,
                  router:this.$router
                });

            this.$store.dispatch('issue/action_fetch_specific_topic',{
            id: this.id,
            toast:this.$toast,
            router:this.$router
          });


           this.$store.dispatch('issue/action_fetch_specific_topic_comments',{
                  id: this.id,
                  toast:this.$toast,
                  router:this.$router
                });

this.isLoading = false;
          this.form.message = ''

      }





    }
  },
   computed:{
        ...mapGetters({
          specific_issue: 'issue/specific_issue',
          support_comments: 'issue/comments'
        }),

  },
}
</script>

<style scoped>

.leadingx7{
  line-height: 2;
}
</style>
