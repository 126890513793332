import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Header from "@/components/uicomponents/headers/Header";
import AuthRdeHeaders from "@/components/uicomponents/headers/AuthRdeHeaders";
import AuthHeaders from "@/components/uicomponents/headers/AuthHeaders";
import RdeMenu from "@/components/uicomponents/menus/RdeMenu";
import RdeMenuMobile from "@/components/uicomponents/menus/RdeMenuMobile";
import Menu from "@/components/uicomponents/menus/Menu";
import RetailerMenuMobile from "@/components/uicomponents/menus/RetailerMenuMobile";
import CustomerMenu from "@/components/uicomponents/menus/CustomerMenu"
import CustomerMenuMobile from "@/components/uicomponents/menus/CustomerMenuMobile";
import InboxCard from "@/components/uicomponents/cards/inboxCard";
import loading from "@/components/uicomponents/loader/loading";
import toggle from "@/components/uicomponents/toggle/toggle";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import './assets/tailwind.css'
 // import Datepicker from 'vue3-date-time-picker';
 // import 'vue3-date-time-picker/dist/main.css';
// import Datepicker from 'vue3-datepicker'

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


import pagination from "@/components/uicomponents/pagination/pagination";

import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'


import axios from 'axios'
import VueAxios from 'vue-axios'
//UI Components

import {Tabs, Tab} from 'vue3-tabs-component';
import Vuetable from 'vue3-vuetable'
import DynamicTable from "@/components/uicomponents/tables/DynamicTable";

import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";







 createApp(App)
    .component('tabs', Tabs)
    .component('tab', Tab)
    .component('pheader',Header)
    .component('aheader',AuthHeaders)
    .component('rdeheader',AuthRdeHeaders)
    .component('rdemenu',RdeMenu)
    .component('rdemenumobile',RdeMenuMobile)
    .component('amenu',Menu)
    .component('amenumobile',RetailerMenuMobile)
    .component('cmenu',CustomerMenu)
    .component('cmenumobile', CustomerMenuMobile)
    .component('loading',loading)
    .component('toggle',toggle)
     .component('Datepicker', Datepicker)
    .component('pagination',pagination)
    .component("inboxcard",InboxCard)
    .component('dymanic-table',DynamicTable)
    .use(ElementPlus)
    .use(Vuetable)
    .use(store)
    .use(router)
    .use(VueChartkick)

    // .use(VueTableDynamic)
    .use(VueToast, {
        // One of the options
        position: 'top',
        duration: 5000,
        dismissible:true
    })
    .use(VueAxios, axios)
  .mount('#app')


   // window.axios.get('https://example.com').then(r => console.log(r.data));
