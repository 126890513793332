<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-1/2 align-middle my-8 mb-50 ">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

          <div class="flex flex-col bg-white rounded p-6">
            <div class="flex items-center justify-center space-x-4 mb-10">
              <h3 class="text-lg leading-6 font-medium text-expressgray" id="modal-title"> Change Device Label for DEVID: {{mac_address}} </h3>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Enter New Label </p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text"  placeholder="Example (My Iphone 6)" class="border-2 rounded p-3 w-full" v-model.trim="form.new_label"/>
              </div>
            </div>



            <div class="flex  items-center justify-between mt-10 mb-5 bg-gray-50 ">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="completeTransaction">Update Device Label</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="cancelTransaction">Close</button>
            </div>




          </div>




        </div>

      </div>
    </div>
  </div>
</template>

<script>



export default {
  name: "add_retailer_model",
  props: ['mac_address'],
  emits:["completeTransaction","cancelTransaction"],
  data(){
    return{
      form:{
        new_label:'',
      },


    }
  },

  methods: {
    resetForm: function(){
      for (var item in this.form)
      {
        this.form[item] = null
      }
},
    completeTransaction()  {

      if (this.form.new_label.length > 1 && this.form.new_label  !== '')
       {
        this.$emit('completeTransaction', [this.form,this.mac_address])
      }else{
          //console.log('missing fields')
          this.$toast.error(`Error Missing fields: Device Name Required`)
      }
    },
    cancelTransaction: function (e) {
      this.$emit('cancelTransaction')
      this.resetForm()
    }
  }


}
</script>

<style scoped>

</style>
