<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-1/2 align-middle my-8 mb-50 ">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

          <div class="flex flex-col bg-white rounded p-6">
            <div class="flex items-center justify-center space-x-4 mb-10">
              <h3 class="text-lg leading-6 font-medium text-expressgray" id="modal-title"> Admin Credit Retailer Wallet  </h3>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Retailer Business Name</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                    <p>{{business_name}}</p>
               </div>
            </div>


            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Retailer Mobile</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <p>{{mobile}}</p>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3"> Country</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <p>{{country}}</p>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3"> Unique ID</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <p>{{user_id}}</p>
              </div>
            </div>


            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Enter Recharge Amount</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="number" class="border-2 rounded p-3 w-full" v-model.trim="amount" placeholder="0" min="0"/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Bank Payment Reference</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="payment_ref" placeholder="Optional Payment Reference"/>
              </div>
            </div>

            <div class="flex  items-center justify-between mt-4 ">
              <p class="inline w-1/3">Currency</p>
              <div class="flex space-x-2 w-2/3 justify-between">
                {{currency}}
              </div>
            </div>




                            <!--            <div class="flex  items-center justify-between mt-4 ">-->
<!--              <p class="inline w-1/3">Phone</p>-->
<!--              <div class="flex space-x-2 w-2/3 justify-between">-->
<!--                <input  type="text" class="border-2 rounded p-3 w-full" v-model.trim="form.phone" placeholder="e.g 234XXXXXXXXXX"/>-->
<!--              </div>-->
<!--            </div>-->



            <div class="flex  items-center justify-between mt-10 mb-5 bg-gray-50 ">
              <button type="button" v-if="disable_button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" @click="completeRechargeTransaction">Top Up Retailer</button>
              <button type="button"  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="cancelRechargeTransaction">Close</button>
            </div>




          </div>




        </div>

      </div>
    </div>
  </div>
</template>

<script>



export default {
  name: "add_retailer_model",
  props: ['country','user_id','business_name','mobile'],
  emits:["completeRechargeTransaction","cancelRechargeTransaction"],
  created() {
    if(this.country === 'Nigeria'){
      this.currency = 'NGN'
    }else{
      this.currency = 'GHS'
    }
  },
  data(){
    return{
      amount: null,
      recharge_type : "administrator_top_up",
      currency: null,
      disable_button:false,
      payment_ref:null
    }
  },
  watch:{
    amount: function (newval){
      if(this.country === 'Nigeria'){
        this.currency = 'NGN'
      }else{
        this.currency = 'GHS'
      }
      if(newval<=0){
        this.amount=null
        this.disable_button  = false
      }else{
        this.disable_button = true
      }
    }
  },




  methods: {


    completeRechargeTransaction()  {
        console.log("completeRechargeTransaction triggered")
      this.$store.dispatch('admins/action_admin_update_retailer_balance',{
        retailer_id: this.user_id,
        amount: this.amount,
        currency: this.currency,
        payment_ref: this.payment_ref,
        toast:this.$toast,
        router:this.$router
      });
      this.$emit('completeRechargeTransaction')
      // action_admin_update_retailer_balance
    },
    cancelRechargeTransaction: function (e) {
      console.log(e)
      this.$emit('cancelRechargeTransaction')
    }
  }


}
</script>

<style scoped>

</style>
