import mutations from './mutations'
import getters from './getters'
import actions from './actions'

export default {
    namespaced: true,
    state(){
        return {
            // enviroment: 'development',
            customers: [],
            payment_history:[],
            customer_issues:[],
            customer_list:[]


        }
    },
    mutations,
    actions,
    getters
}
