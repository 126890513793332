<template>
  <div class="flex justify-center">
    <div class="form-check form-switch">

   <div class="flex items-center justify-center w-full " id="flexSwitchCheckDefault">
        <label for="toggleB" class="flex items-center cursor-pointer">
          <div class="relative">
            <input type="checkbox" id="toggleB" class="sr-only" v-model="cvalue">
            <div class="block bg-gray-600 w-10 h-6 rounded-full"></div>
            <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
          </div>
          <div class="ml-3 text-gray-700 font-medium">
            {{title}}
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "toggle",
  emit:['toggle'],
  props:['title'],
  data(){
    return {
      cvalue: false
    }
  },
  watch:{
    cvalue: function (value){
      console.log("child value", value)

      this.$emit('toggle',{"selected":value})
    }
  }
}
</script>

<style scoped>
/* Toggle A */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
</style>
