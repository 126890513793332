<template>
  <div class="flex flex-col xl:flex-row xl:space-x-3 space-y-2 items-center">
  <img class="w-40" :src="imageurl" alt="">
    <div class="flex flex-col text-center space-y-2 xl:space-y-0 xl:text-left">
      <h1  class="text-black font-normal"> {{ title }} </h1>
      <p class="text-expressgray sm:text-sm text-xs font-medium">{{ label }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "summarycard",
  props:['imageurl', 'title', 'label']
}
</script>

<style scoped>

</style>
