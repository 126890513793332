import axios from "axios";

export default {
  async action_products({ context, state, commit, rootState }, payload) {
    // eslint-disable-line no-unused-vars
    const url = `${rootState.baseurl}/products?currency=${payload.currency}`;
    const token = rootState.authentication.token;
    try {
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (response.status === 200) {
        commit("setProducts", response.data.data);
      }
    } catch (error) {
      console.log("error", error);
      payload.toast.error("An error occurred, please try again");
    }
  },
};
