import mutations from './mutations'
import getters from './getters'
import actions from './actions'

export default {
    namespaced: true,
    state(){
        return {
            // enviroment: 'development',
            enviroment: 'production',
            baseurl:  '',
            token: null,
            paystack_keys: null,
            user:{
                id : null,
                uid : null ,
                name : null ,
                email:null,
                phone : null,
                business_name : null,
                address :null ,
                primaryaccount : null,
                country : null,
                latitude : null,
                longitude : null,
                created_at : null,
                updated_at: null,
                account_types : [],
                islogged: false
            },
            hotspot:{
                mac: null
            }
        }
    },
    mutations,
    actions,
    getters
}
