<template>
  <div
  class="w-full rounded shadow-lg bg-white text-expressgray p-6"
  >

    <div class="flex flex-row space-x-4 items-center justify-between text-expressgray text-sm mb-10">
      <router-link to="/retailer/overview/report">
        <div class="space-x-1 flex items-center cursor-pointer text-xl border-2 rounded-xl bg-green-600 p-3  text-white hover:bg-green-700">
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6.707 4.879A3 3 0 018.828 4H15a3 3 0 013 3v6a3 3 0 01-3 3H8.828a3 3 0 01-2.12-.879l-4.415-4.414a1 1 0 010-1.414l4.414-4.414zm4 2.414a1 1 0 00-1.414 1.414L10.586 10l-1.293 1.293a1 1 0 101.414 1.414L12 11.414l1.293 1.293a1 1 0 001.414-1.414L13.414 10l1.293-1.293a1 1 0 00-1.414-1.414L12 8.586l-1.293-1.293z" clip-rule="evenodd"></path></svg>
          <p class="ml-3 ">Back</p>
        </div>
      </router-link>
    </div>


    <div class="flex flex-col items-center lg:items-start justify-center w-full">

        

        <h1 class="text-express text-2xl font-semibold mb-5 font-serif"> Report Problem</h1> 
        <!-- {{this.topic_uid}} -->
      <div class="mb-5 text-center lg:text-left">
        <p>Please select a problem category and provide us with necessary </p>
        <p>Information about your complaints and issues. Our support team will reach out </p>
        <p>as soon as possible. </p>
      </div>

      <div class="flex flex-col space-y-4 mt-10 md:w-2/3 w-11/12">
        <p>* Problem Category</p>
        <div class="w-full">
          <select class="p-4  border-2 border-express rounded shadow-2xl w-full" name="category" id="category_id" v-model='form.category'>
            <option value="technical_issue">Technical Issue</option>
            <option value="payment_issue">Payment Issue</option>
            <option value="bug_report">Bug Report</option>
            <option value="internet_connectivity">Internet Connectivity</option>
            <option value="others">Others</option>
          </select>

        </div>
      </div>

  <div class="flex flex-col space-y-4 mt-10 md:w-2/3 w-11/12">
        <p>* Title</p>
        <div class="w-full">
         <input class="p-4 flex-grow-0  border-2 border-express rounded shadow-2xl w-full" type="text" placeholder="Support Title (Required)"  v-model="form.title">

        </div>
      </div>

      <div class="flex flex-col space-y-4 mt-10 md:w-2/3 w-11/12">
        <p>* Description</p>
        <div class="w-full">
          <textarea class="text-black border-2 w-full rounded shadow-2xl p-3 border-express " name="description" id="" cols="30" rows="15" v-model="form.message"></textarea>
        </div>
      </div>


      <div class="flex flex-col space-y-4 mt-10 mb-10 md:w-2/3 w-11/12">
        <p class="">Optional (Upload Attachment(s))</p>
        <input type="file" @change="previewFiles">

        <div class="mt-3 flex flex-col w-full">
            <div v-for="file, index in files" :key=index>
                    <div class="flex justify-between space-x-3 border-2 p-2"> 
                        {{file[0]}}
                        <button class="text-red-500" @click="removeFile(index)"> Remove </button>
                    </div>
            </div>
         

        </div>

        <!-- <div class="flex w-2/3">
          <button class=" flex space-x-4 p-3  rounded bg-gray-200 cursor-pointer flex-grow-0 flex-nowrap">
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd"></path></svg>
            Add Attachment
          </button>
        </div> -->
      </div>



      <loading v-if="isLoading" message="Loading, please wait"></loading>
      <div class="flex flex-col space-y-4 mb-10 md:w-2/3 w-11/12" v-else>
        <div class="w-full text-center">
          <button class="text-center  space-x-4 p-3  rounded bg-expressbutton cursor-pointer  text-white w-full  items-center" @click="upload_issue">Submit</button>
        </div>
      </div>

      



    </div>



  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "NewIssue",
  created(){
    // this.topic_uid = this.uuid()

  },
  data(){
    return {
      isLoading: false,
      // topic_uid: null,
      files:[],
      filenumber:1,
      form: {
        category: '',
        title: '',
        message: '',

      }
    }
  },
  computed:{
        ...mapGetters({
          topic_uid: 'issue/topic_uid',
        }),
       
  },
  methods:{
    removeFile (index){
        console.log("remove file at a particular location",index)
        let temp = this.files 
        temp.splice(index, 1);
        this.files = temp
    },
    uuid: ()=>{
        const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
        const xAndYOnly = /[xy]/g;

        return template.replace(xAndYOnly, (character) => {
            const randomNo =  Math.floor(Math.random() * 16);
            const newValue = character === 'x' ? randomNo : (randomNo & 0x3) | 0x8;

            return newValue.toString(16);
        });
    },
     previewFiles(event) {
      // console.log(event.target.files);

     console.log('files',  event.target.files[0])
       let file = event.target.files[0];
      //  let key = `Attachment_${this.filenumber}`
      if (typeof file !== "undefined") {
            let key = file.name
            this.filenumber = this.filenumber+1;
            this.files.push([
              key,file
            ])
      }
   },
      upload_issue(){

         this.$store.dispatch('issue/action_setTopicUid',{topic_uid: this.uuid()})
       
         this.isLoading = true 
         // console.log("upload issue clicked")
          if (this.form.category == '' || this.form.title == '' || this.form.message == '' ){
              this.$toast.error("Al fields required")
             // console.log('missing field')
          } 
          else {
              //  let temp = []
               this.files.map((value)=>{
                 let vm = this
             let file = value[1]  
             this.$store.dispatch('issue/action_upload_step_1_file',{
                   file: file,
                   toast:this.$toast,
                   router:this.$router
                 }).then((resolve)=>{
                    
                    let mybody = {
                         reference : this.topic_uid ,
                         section : vm.form.category ,
                         path : resolve ,
                         toast:this.$toast,
                        router:this.$router
                    }

                    console.log('mybody',mybody)
                    this.$store.dispatch('issue/action_upload_step_2_file_url',mybody)
                   
                   // temp.push(resolve)
                    //  console.log('temp',temp)
                    //  console.log('newissue resolve',resolve)
                 })
                 
              // console.log('newIssue url', url)
              
          })

          let vm = this

          const body = {
             topic_uid : this.topic_uid, 
             category : vm.form.category,
             title : vm.form.title,
             message : vm.form.message 
          }

          this.$store.dispatch('issue/action_create_support_issue',body).then((resolve=>{
            if(resolve){
               setTimeout(()=>{
                this.isLoading = false
                  this.$toast.success("Support created successfully")
                  this.form.title=''
                  this.form.message=''
                  //this.topic_uid=this.uuid()
                //  this.$router.replace('/support')
              },10000)
            }
            else{
               setTimeout(()=>{
                this.isLoading = false
                 this.$toast.error("An error occurred, please try again")
              },10000)
            }
          }))
        
             
          }
           
           }
         


  }
}
</script>

<style scoped>

</style>
