<template>
  <div class="flex flex-col bg-white ">

    <div class="flex items-center justify-center h-screen bg-loginbackgroundmini bg-no-repeat bg-cover w-full">
      <div class="w-full mx-40">
        <div  class="  shadow-2xl rounded-2xl  m-auto flex flex-col">
<!--          New User -->

<!--          http://localhost:8080/#/loginnow?ga_ssid=@Tizeti%20Free%20Wifi%20CEO&ga_ap_mac=58-C1-7A-12-19-91&ga_nas_id=E410-CEO-OFFICE&ga_srvr=192.168.15.43&ga_cmac=BC-91-B5-E1-E1-12&ga_Qv=eEROBR86HBgAGDEEVgQAGw4UWRUCACYVMgFPR1xZXidfVCRGXCxFUHBZMCNLBhMUMww.&ga_orig_url=http://public.transacme.com/instantApps/developer_picture/1618305140564.jpg-->

                <div class="flex flex-col w-full  space-y-10 bg-white" v-if="view == 'new_user'">
                     <h1 class="text-bold bg-green-600  text-2xl text-white p-10 text-center ">Welcome to Tizeti Hotspot , Registration form</h1>

                      <div class="flex flex-col space-y-8 p-5">
                         <input type="text" name="name"   v-model="new_user.full_name"  placeholder="Full-Name *" class="border-2 p-4">
                         <input type="email" name="email" v-model="new_user.email" placeholder="Email Address *"  class="border-2 p-4">
<!--                        <select name="country" id=""  class="border-2 p-4">-->
<!--                          <option>Nigeria</option>-->
<!--                          <option>Ghana</option>-->
<!--                        </select>-->
                        <input type="phone" name="phone" v-model="new_user.phone" placeholder="Phone Number (format 2348012345678 for Nigeria or 233501234567 for Ghana)"  class="border-2 p-4 mb-4">
                     </div>

                  <button class="text-center bg-white hover:bg-green-500 hover:text-white p-5 text-2xl mx-80 mt-10 ">Register to Get Free-Wifi </button>


                  <div class="p-10 text-center">
                    <p class="text-xl"> Contact  </p>
                    <p> Ghana: hotspot@ghanawifi.com  or Nigeria: hotspot@tizeti.com </p>
                    <p> For enquires or reporting connectivity issues </p>
                    <p> Dev ID: {{get_data.ga_cmac.replaceAll('-',':') }} </p>



                  </div>


                </div>

                <div class="flex flex-col w-full  space-y-10 bg-white"  v-else-if="view == 'expired_user'">
                  <h1>Expired User Data Renew</h1>
                   <input type="number" v-model="dummy_datapack" placeholder="Dummy Data Pack" class="p-4 border-2 border-green-500">
                  <form name="sendin" :action=linkloginonly method="post">
                    <input type="hidden" name="ga_user" :value="get_data.ga_cmac" />
                    <input type="hidden" name="ga_pass" :value="get_data.ga_cmac" />
                    <input type="hidden" name="dst" value="get_data.ga_orig_url" />
                    <input type="hidden" name="popup" value="true" />
                    <button v-if="dummy_datapack>0" type="submit"  class="p-4 bg-green-500 text-white" > (Test Purposes Only) Continue Browsing</button>
                  </form>
<!--                  <button v-if="dummy_datapack>0" @click="login_now" >(Test Purposes Only) Continue Browsing</button>-->
                </div>

                <div class="flex flex-col w-full  space-y-10 bg-white"  v-else-if="view == 'active_user'">
                  <h1>Active User continue Browsing</h1>
                  <input type="number" v-model="dummy_datapack" placeholder="Dummy Data Pack"  class="p-4 border-2 border-green-500"  >


                  <form name="sendin" :action=linkloginonly method="post">
                    <input type="hidden" name="ga_user" :value="get_data.ga_cmac" />
                    <input type="hidden" name="ga_pass" :value="get_data.ga_cmac" />
                    <input type="hidden" name="dst" value="get_data.ga_orig_url" />
                    <input type="hidden" name="popup" value="true" />
                    <button v-if="dummy_datapack>0" type="submit" class="p-4 bg-green-500 text-white"  > (Test Purposes Only) Continue Browsing</button>
                  </form>



                </div>

                <div class="flex items-center justify-center p-20 pace-y-10 bg-white  "  v-else-if="view == 'loading'">
                  <h1>Loading please wait...</h1>
                </div>

<!--            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto asperiores at consectetur dolore doloremque dolores ea est et exercitationem fugiat hic necessitatibus numquam omnis perspiciatis quam quasi, sint vero voluptas?-->

        </div>
      </div>

    </div>



  </div>
</template>

<script>
export default {
  name: "LoginNow",
  mounted() {
    console.log("LoginNow mounted called")
   // http://localhost:8080/#/loginnow?ga_ssid=@Tizeti%20Free%20Wifi%20CEO&ga_ap_mac=58-C1-7A-12-19-91&ga_nas_id=E410-CEO-OFFICE&ga_srvr=192.168.15.43&ga_cmac=BC-91-B5-E1-E1-12&ga_Qv=eEROBR86HBgAGDEEVgQAGw4UWRUCACYVMgFPR1xZXidfVCRGXCxFUHBZMCNLBhMUMww.&ga_orig_url=http://public.transacme.com/instantApps/developer_picture/1618305140564.jpg
   // let parameters = this.$route.query
    //console.log(parameters)

    this.get_data.ga_ssid = this.$route.query.ga_ssid
    this.get_data.ga_ap_mac = this.$route.query.ga_ap_mac
    this.get_data.ga_nas_id = this.$route.query.ga_nas_id
    this.get_data.ga_srvr = this.$route.query.ga_srvr
    this.get_data.ga_cmac =this.$route.query.ga_cmac.replaceAll('-',':')
    this.get_data.ga_Qv = this.$route.query.ga_Qv
    this.get_data.ga_orig_url = this.$route.query.ga_orig_url

    this.linkloginonly = `http://${this.get_data.ga_srvr}:880/cgi-bin/hotspot_login.cgi?ga_ssid=${this.get_data.ga_ssid}&ga_ap_mac=${this.get_data.ga_ap_mac}&ga_nas_id=${this.get_data.ga_nas_id}&ga_srvr=${this.get_data.ga_srvr}&ga_cmac=${this.get_data.ga_cmac}&ga_Qv=${this.get_data.ga_Qv}&ga_orig_url=${this.get_data.ga_orig_url}`;
    this.linklogoutonly = `http://${this.get_data.ga_srvr}:880/cgi-bin/hotspot_login.cgi?ga_ssid=${this.get_data.ga_ssid}&ga_ap_mac=${this.get_data.ga_ap_mac}&ga_nas_id=${this.get_data.ga_nas_id}&ga_srvr=${this.get_data.ga_srvr}&ga_cmac=${this.get_data.ga_cmac}&ga_Qv=${this.get_data.ga_Qv}&ga_orig_url=${this.get_data.ga_orig_url}`;


    if(    this.get_data.ga_cmac !== ''){

      // this.$store.dispatch('authentication/action_get_hotspot_user_information',{
      //   macaddress :  this.get_data.ga_cmac,
      //   toast:this.$toast,
      //   router:this.$router
      // })

      //Get User Information

      let mac =  this.get_data.ga_cmac
      // let mac = '26:16:06:B3:9B:1D';
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "macAddress": mac,
        "phoneNumber": "N/A",
        "macid": mac,
        "category": "macaddress"
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      let hotspotUserData  =   fetch("https://api.tizeti.com/api/v1/getUserInfoWithPhoneorMac", requestOptions)
          .then(response => {
            return response.text();
          })
          .then( result => {
                if(typeof result  === 'string' && result == 'customer info could not be retreived'){
                //  console.log("result",result)
                  this.view = "new_user";
                }else{
                  let data = JSON.parse(result)
                  //check if user has datapack
                  // let datapack = 0;
                  if(this.dummy_datapack >0){
                    this.view  = "active_user"
                  }else{
                    this.view  = "expired_user"
                  }



                  //check if user has finished datapack
                }
              }
          )
          //console.log('hotspot result',result.json()))
          .catch(error => console.log('error', error));













    }






     // console.log("get_data",this.get_data)




  },
  data(){
    // views = [loading, new_user,active_user,expired_user]
    return {
      year:  new Date().getFullYear(),
      view:  "loading",
      loading: true,
      linkloginonly:'',
      linklogoutonly:'',
      dummy_datapack:null,
      get_data:{
        ga_ssid:"",
        ga_ap_mac:"",
        ga_nas_id:"",
        ga_srvr:"",
        ga_cmac:"",
        ga_Qv:"",
        ga_orig_url:""
      },
      new_user:{
        phone:'',
        full_name:'',
        email:''
      }
    }
  },
  methods:{
    validate_phone(){
      if(this.new_user.phone.match(/234[0-9]{10}/)  || this.new_user.phone.match(/233[0-9]{9}/) ){
         return true;
      }
      else{
        this.$toast.error( 'Incorrect Phone Number Pattern')
        return false;
      }
    },
    validate_full_name(){
      if(this.new_user.full_name.length >3 ){
          return true;
        }else{
        this.$toast.error( 'Full Name Required')
        return false;
      }
    },
    register_new_user(){

    },
    check_data_balance(){

    },
    make_payment(){

    },
    login_now(){

      // <form name="sendin" action="<?php echo $linkloginonly; ?>" method="post">
      //   <input type="hidden" name="ga_user" value="<?php echo $device_mac; ?>" />
      //   <input type="hidden" name="ga_pass" value="<?php echo $device_mac; ?>" />
      //   <input type="hidden" name="dst" value="<?php echo $linkorig; ?>" />
      //   <input type="hidden" name="popup" value="true" />
      // </form>

    },
    logout_now(){

    }
  }
}
</script>

<style scoped>

</style>
