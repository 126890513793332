<template>
  <div class="flex flex-col m-1 md:m-5 bg-white shadow-2xl overflow-x-auto">
    <h1 class="text-expressgray text-2xl rounded-2xl p-5 text-center mb-5 font-semibold font-serif"> View Reported Issues </h1>


       <div v-if="customer_issues.data.length>0">

         <table class="table-auto w-full">
           <thead class="mb-5">
             <tr class="bg-gray-500 py-5">
                 <th class="px-2">Category</th>
                 <th class="px-2">Message</th>
                 <th class="px-2">Ticket Status</th>
                 <th class="px-2">Created_At</th>
                 <th class="px-2">Details</th>
              </tr>
           </thead>
           <tbody class="">
           <tr class="my-2" v-for="issue in customer_issues.data" :key="issue.id">
             <td class="px-2">{{ issue.category }}</td>

<!--             <td class="w-auto"><textarea cols="50" rows="1" :value="issue.message"></textarea> </td>-->
             <td class="text-clip px-2"> {{ truncate(issue.message) }}  </td>
             <td :class="issue.handled ? 'text-green-600 px-2': 'text-red-600 px-2'">{{ issue.handled ? 'Resolved':'In Progress' }}</td>
             <td class="px-2"> {{ issue.created_at }} </td>
             <td class="px-2"> <button class="bg-green-600 p-2 text-white my-1" @click="view_details(issue)"> View Details</button></td>
<!--             <td>{{ issue.adminstrator }} </td>-->
           </tr>

           </tbody>
         </table>


       </div>
        <div v-else>
          <h3 class="text-center text-2xl">No Reports found</h3>
        </div>

    <div v-if="showModel">
      <customerisssuemodals
          :category=ticket.category
          :message=ticket.message
          :date=ticket.created_at
          :status="ticket.handled ? 'Resolved':'In Progress'"
          @completeTransaction=completeTransaction
          @cancelTransaction=cancelTransaction />
    </div>


    <div class="max-w-lg flex justify-start my-12 w-full">
      <pagination :total-pages="totalPages"
                  :total="total"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :has-more-pages="hasMorePages"
                  @pagechanged="showMore">
      </pagination>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import customerisssuemodals from "@/components/uicomponents/models/customerisssuemodals";

export default {
  name: "ViewCustomerReports",
  created() {
    //clear all token and logs
    this.totalPages = this.customer_issues.to
    this.total = this.customer_issues.total
    this.perPage = this.customer_issues.per_page
    this.currentPage = this.customer_issues.current_page
    this.hasMorePages = this.customer_issues.last_page > this.customer_issues.current_page



    this.$store.dispatch('customers/action_customer_reported_issues',{
      page: this.currentPage,
      toast:this.$toast,
      router:this.$router
    });



    //   console.log("messsages",this.messages)
  },
  components: {
    "customerisssuemodals": customerisssuemodals
  },
  data(){
    return {
      ticket: null,
      totalPages: 1,
      total: 10,
      perPage: 5,
      currentPage :  1,
      hasMorePages: true,
      isLoading:false,
      showModel: false
    }
  },
  methods:{
    view_details: function (data){
      this.showModel = true
      this.ticket = data
      console.log("view_details data",data)
    },
    cancelTransaction: function (){
        console.log("cancelTransaction triggered")
      this.showModel = false
    }
  },
  computed:{
    truncate(){
      return (v)=>{
        if (v.length > 20) {
          return v.substring(0, 20) + '...';
        } else {
          return v;
        }
      }
    },
    ...mapGetters({
      customer_issues :'customers/customer_issues',
    }),
  },
}
</script>

<style scoped>

</style>
