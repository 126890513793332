import mutations from './mutations'
import getters from './getters'
import actions from './actions'

export default {
    namespaced: true,
    state(){
        return {
            issues:[],
            topic_uid:null,
            specific_issue:[],
            comments:[]
        }
    },
    mutations,
    actions,
    getters
}