export default {

    issues(state){ 
        return state.issues
    },
    topic_uid(state){
        return state.topic_uid
    },
    specific_issue(state){
        return state.specific_issue
    },
    comments(state){
        return state.comments
    }


}