<template>
  <div class="bg-white shadow-2xl w-full p-5">
    <input class="mb-5 w-full border-2 rounded-2xl p-4" type="text" v-model="search" placeholder="Search Table">
    <table class="w-full p-5 ">
      <tr>
        <th  class="border-2 p-2 text-left" v-for="(column, index) in columns" :key="index"  @click="sortRecords(index)">
          {{column}}
        </th>
      </tr>
      <tbody>
      <tr  v-for="(val , i) in rows" :key="i">
        <td  class="p-5 border-2"> {{val[0]}}</td>
        <td  class="p-5 border-2"> {{val[1]}}</td>
        <td  class="p-5 border-2"> {{val[2]}}</td>
        <td  class="p-5 border-2"> {{val[3]}}</td>
        <td  class="p-5 border-2"> {{val[4]}}</td>
        <td  class="p-5 border-2"> {{val[5]}}</td>
        <td  class="p-5 border-2">
          <label class="switch" v-if="user.primaryaccount === 'super_administrator'">
            <input type="checkbox" :checked="val[5]==='Active'"  @change="enable_disable(val[6],$event.target.checked)">
            <span class="slider round"></span>
          </label>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>


import {mapGetters} from "vuex";

export default {
  name:"RDEListTable",
  props: ['rowRaws'],
  created() {
   // console.log('RDEListTable ',this.user)
  },
  mounted() {
    this.rows =  [...this.rowRaws]
  },
  computed: {
    ...mapGetters({
     user: 'authentication/getUser'

    }),
  },
  data(){
    return {
      search:'',
      columns:[
        'Full-Name',	'Email'	, 'Mobile',	'Country'	,'Accout Type',	'Account Status', 'Enable/Disable Account'
      ],
      row:[],
      rows:[],
      sortIndex: null,
      sortDirection: null

    }
  },
  watch:{
    search: function (newvalue, oldvalue){
      // console.log(newvalue,oldvalue)
      this.onSearch()
    }
  },
  methods: {
    sortRecords: function (index){
      if(this.sortDirection  ==  null){
        this.sortDirection = 'asc'
      }
      this.sortIndex = index
      this.rows = this.rows.sort(
          (rowA,rowB)=> {
            if(typeof (rowA[index]) == 'number' || typeof (rowB[index]) == 'number' ){
              // console.log('number sortting')
              if(this.sortDirection === 'asc' || this.sortDirection == null){
                this.sortDirection = 'desc'
                return rowA[index] - rowB[index]
              }else{
                this.sortDirection = 'asc'
                return  rowB[index] - rowA[index]
              }
              // return rowA[index].localeCompare(rowB[index], undefined, {'numeric': true})
            }else{
              return rowA[index].localeCompare(rowB[index])
            }

          }
      )
    },
    onSearch: function (){
      let term = this.search
      let temp = this.rowRaws
      this.rows = temp.filter(
          row => row.join(" ").includes(term)
      )
    },
    enable_disable(user_id,status){
       console.log('enable_disable triggerred ' , `${JSON.stringify(user_id)} - ${status}`)
      this.$store.dispatch('admins/action_enable_disable_rde_account',{
        user_id: user_id,
        status: status,
        toast:this.$toast,
        router:this.$router
      });




    },
  }
}

</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
