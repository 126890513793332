<template>
  <Doughnut class="pie" :chart-data="chartData"  />
</template>

<script>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'PieChart',
  created() {
    let colours = ['#383961','#BCF4F5','#B4EBCA','#A71D31','#3F0D12','#562C2C','#0E9594'];
    let values = []
    let labels = []

   this.data.forEach((item,index)=>{
     let name = item[0]
     let value =item[1]
     labels[index] = name
     values[index] =  value
   })
    if(this.reversed){
      values = values.reverse()
      labels = labels.reverse()
    }

    this.chartData.labels = labels;
   this.chartData.datasets[0].data =values
     this.chartData.datasets[0].label = this.title


  },
  components: { Doughnut },

  props:["data","title","reversed"],
  data() {
    return {
      chartData: {
        cutoutPercentage: 20,
        responsive: true,
        maintainAspectRatio: false,
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: ['#383961','#BCF4F5','#B4EBCA','#A71D31','#3F0D12','#562C2C'],
            data: []
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.pie {
  /*background-color: #ccf;*/
  /*width: 500px;*/
  /*height: 400px;*/

 display: inline-block; box-sizing: border-box; height: 500px; width: 500px;
}
</style>
