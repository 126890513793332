import { createRouter, createWebHashHistory } from "vue-router";
// import Home from '../views/Home.vue'
import LoginNow from "../views/layouts/public/LoginNow";
import Login from "../views/layouts/public/Login";
import SelectAccount from "@/views/layouts/public/SelectAccount";
// import ManagerOverView from "@/views/layouts/retailer/RetailerOverView";
import Recover from "@/views/layouts/public/Recover";
import MainOverview from "@/views/layouts/retailer/children/MainOverview";
import CustomerMainOverview from "@/views/layouts/customer/children/CustomerMainOverview";
import Customers from "@/views/layouts/retailer/children/Customers";
import CustomerOverView from "@/views/layouts/customer/CustomerOverView";
import PageNotFound from "@/views/layouts/public/PageNotFound";
import payment_approved from "@/components/uicomponents/confirmations/payment_approved";
import Profile from "@/views/layouts/retailer/children/Profile";
import ProfileCustomer from "@/views/layouts/customer/children/Profile";
import TopUp from "@/views/layouts/retailer/children/TopUp";
import PaymentHistory from "@/views/layouts/retailer/children/PaymentHistory";
import WalletPaymentHistory from "@/views/layouts/retailer/children/WalletPaymentHistory";
import SalesReport from "@/views/layouts/retailer/children/SalesReport";
import ReportIssue from "@/views/layouts/retailer/children/ReportIssue";
import ReportDetails from "@/views/layouts/retailer/children/ReportIssueChildren/ReportDetails";
import NewIssue from "@/views/layouts/retailer/children/ReportIssueChildren/NewIssue";
import Inbox from "@/views/layouts/retailer/children/Inbox";
import RetailerOverView from "@/views/layouts/retailer/RetailerOverView";
import SearchView from "@/views/layouts/SearchView";
import RdeMain from "@/views/layouts/rde/children/RdeMain";
import RdeAnalytics from "@/views/layouts/rde/children/RdeAnalytics";
import CustomerDataTransfer from "@/views/layouts/rde/children/CustomerDataTransfer";
import CustomerUsageHistory from "@/views/layouts/rde/children/CustomerUsageHistory";
import SendMessage from "@/views/layouts/rde/children/SendMessage";
import SupportTicket from "@/views/layouts/rde/children/SupportTicket";
import Decommissioned from "@/views/layouts/rde/children/Decommissioned";
import TotalRetailersPurchase from "@/views/layouts/rde/children/TotalRetailersPurchase";
import Users from "@/views/layouts/rde/children/Users";
import IndividualJourneyPlan from "@/views/layouts/rde/children/IndividualJourneyPlan";
import CreateCallCard from "@/views/layouts/rde/children/CreateCallCard";
import Products from "@/views/layouts/customer/children/Products";
import ViewRetailers from "@/views/layouts/customer/children/ViewRetailers";
import CustomerPaymentHistory from "@/views/layouts/customer/children/CustomerPaymentHistory";
import CustomerReport from "@/views/layouts/customer/children/CustomerReport";
import ViewCustomerReports from "@/views/layouts/customer/children/ViewCustomerReports";
import AdminReportDetails from "@/views/layouts/rde/children/AdminReportIssueChildren/AdminReportDetails";
import AdminSupportTicket from "@/views/layouts/rde/children/AdminReportIssueChildren/AdminSupportTicket";
import RdeOverView from "@/views/layouts/rde/RdeOverView";

import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Login,
    meta: { requiresUnAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresUnAuth: true },
  },
  {
    path: "/loginnow",
    name: "loginnow",
    component: LoginNow,
    meta: { requiresUnAuth: true },
  },
  {
    path: "/accounts",
    name: "SelectAccount",
    component: SelectAccount,
    meta: {
      requiresAuth: true,
      roles: [
        "customer",
        "retailer",
        "rde",
        "junior_administrator",
        "super_administrator",
      ],
    },
  },
  {
    path: "/recover",
    name: "Recover",
    component: Recover,
    meta: { requiresUnAuth: true },
  },
  {
    path: "/retailer/overview",
    name: "RetailerOverView",
    component: RetailerOverView,
    meta: {
      requiresAuth: true,
      roles: ["retailer"],
    },
    children: [
      {
        path: "",
        component: MainOverview,
      },
      {
        path: "main",
        component: MainOverview,
      },
      {
        path: "customers",
        component: Customers,
      },
      {
        path: "inbox",
        component: Inbox,
      },
      {
        path: "profile",
        component: Profile,
      },
      {
        path: "salesreport",
        component: SalesReport,
      },
      {
        path: "topup",
        component: TopUp,
      },
      {
        path: "topup/history",
        component: PaymentHistory,
      },
      {
        path: "wallet_topup/history",
        component: WalletPaymentHistory,
      },
      {
        path: "report",
        component: ReportIssue,
      },
      {
        path: "/report/:id/details",
        component: ReportDetails,
        props: true,
      },
      {
        path: "/report/newIssue",
        component: NewIssue,
      },
      {
        path: "/:id/success",
        component: payment_approved,
        props: true,
      },
    ],
  },
  {
    path: "/customer/overview",
    name: "Customers",
    component: CustomerOverView,
    meta: {
      requiresAuth: true,
      roles: ["customer"],
    },
    children: [
      {
        path: "",
        component: CustomerMainOverview,
      },
      {
        path: "main",
        component: CustomerMainOverview,
      },
      {
        path: "products",
        component: Products,
      },
      {
        path: "settings",
        component: ProfileCustomer,
      },
      {
        path: "history",
        component: CustomerPaymentHistory,
      },
      {
        path: "retailers",
        component: ViewRetailers,
      },
      {
        path: "report",
        component: CustomerReport,
      },
      {
        path: "report/view",
        component: ViewCustomerReports,
      },
    ],
  },

  {
    path: "/administrator/overview",
    name: "RdeOverView",
    component: RdeOverView,
    meta: {
      requiresAuth: true,
      roles: ["rde", "junior_administrator", "super_administrator"],
    },
    children: [
      {
        path: "",
        component: RdeMain,
      },
      {
        path: "/main",
        component: RdeMain,
      },
      {
        path: "/analytics",
        component: RdeAnalytics,
      },
      {
        path: "/search/:search_value",
        component: SearchView,
        props: true,
      },
      {
        path: "/sendmessage",
        component: SendMessage,
      },
      {
        path: "/supportticket",
        component: AdminSupportTicket,
      },
      {
        path: "/decommissioned",
        component: Decommissioned,
      },
      {
        path: "/data-transfer",
        component: CustomerDataTransfer,
      },
      {
        path: "/retailerspurchasehistory",
        component: TotalRetailersPurchase,
      },
      {
        path: "/users-history",
        component: CustomerUsageHistory,
      },
      {
        path: "/users",
        component: Users,
      },
      {
        path: "/admin/report/:id/details",
        component: AdminReportDetails,
        props: true,
      },
      {
        path: "/:id/journeyplan",
        component: IndividualJourneyPlan,
      },
      {
        path: "/create/callcard",
        component: CreateCallCard,
      },
      // {
      //   path:  'inbox',
      //   component: Inbox
      // },
      // {
      //   path:  'profile',
      //   component: Profile
      // },
      // {
      //   path:  'salesreport',
      //   component: SalesReport
      // },
      // {
      //   path:  'topup',
      //   component: TopUp
      // },
      // {
      //   path:  'topup/history',
      //   component: PaymentHistory
      // },
      // {
      //   path: 'report',
      //   component: ReportIssue
      // },
      // {
      //   path: '/report/:id/details',
      //   component: ReportDetails,
      //   props:true
      // },
      // {
      //   path:  '/report/newIssue',
      //   component: NewIssue,
      // },
      // {
      //   path: '/:id/success',
      //   component: payment_approved,
      //   props:true
      // }
    ],
  },

  {
    path: "/404",
    name: "PageNotExist",
    component: PageNotFound,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  //   console.log("Global Before Each")
  //  console.log(to,from)
  //  next();

  let authenticated = store.getters["authentication/isAuthenticated"];
  let primaryaccount = store.getters["authentication/getprimaryaccount"];
  // console.log("store.getters.isAuthenticated ",authenticated)
  // console.log("primaryaccount",primaryaccount)

  if (
    to.meta.requiresAuth &&
    authenticated &&
    to.meta.roles.includes(primaryaccount)
  ) {
    // console.log("to.meta.requiresAuth && authenticated")
    next();
  } else if (to.meta.requiresUnAuth) {
    // console.log("to.meta.requiresUnAuth")
    next();
  } else {
    // console.log("nothing")
    next("/");
  }
});

export default router;
