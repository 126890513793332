<template>
  <Line :chart-data="chartData" />
</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'LineChart',
  created() {
    let values = Object.values(this.data)
    let labels =  Object.keys(this.data)

    if(this.reversed){
      values = values.reverse()
      labels = labels.reverse()
    }

    this.chartData.labels = labels;
   this.chartData.datasets[0].data =values
     this.chartData.datasets[0].label = this.title

  },
  components: { Line },

  props:["data","title","reversed"],
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '#383961',
            data: []
          }
        ]
      }
    }
  }
}
</script>
