<template>
  <div class="flex flex-col dmcenter">
    <div class="flex space-x-4 justify-between p-5 items-center bg-white shadow-2xl">
      <h1 class="text-bold"> Total Retailers {{ total_retailers }}    {{country}}  </h1>
      <div class="flex space-x-4">

        <button v-if="getUser.primaryaccount == 'junior_administrator' || getUser.primaryaccount == 'super_administrator'  " class="rounded px-6 py-3 border-2 flex justify-evenly items-center space-x-4 " @click="show_add_retailer=!show_add_retailer"> <i class="fas fa-plus mr-2"></i> Add Retailer </button>
        <button class="rounded lg:px-6 px-4 md:py-3 py-1 border-2 flex justify-evenly items-center space-x-3 sm:text-base text-xs" @click="download_csv"> <i class="fas fa-cloud-download-alt mr-2"></i> Download </button>
        <button @click="reload_list" class="rounded px-6 py-3 border-2 flex justify-evenly items-center space-x-4 "> <i class="fas fa-sync mr-2"></i> Refresh </button>
      </div>
    </div>
    <div class="flex w-full flex-col mt-4 bg-white shadow-2xl">
      <div v-if="isLoading">
        <loading message="Loading, please wait"></loading>
      </div>
      <div v-else>
        <div v-if="show_add_retailer">
           <add_retailer_model
               :staff_email="getUser.email"
           @completeTransaction="completeTransaction"
           @cancelTransaction="cancelTransaction"
           />
        </div>

        <div  v-if="show_recharge_model">
          <admin_retailer_add_wallet
              :business_name="retailer_form.business_name"
              :country="retailer_form.country"
              :mobile="retailer_form.mobile"
              :user_id="retailer_form.user_id"
              @completeRechargeTransaction="completeRechargeTransaction"
              @cancelRechargeTransaction="cancelRechargeTransaction"
          />
        </div>

        <div v-if="show_retailer_to_decommission">

          <add_retailer_to_decommission
              :name="selected_retailer_name"
              :phone="selected_retailer_mobile"
              :retailer_id="selected_retailer_id"
              @completeDecommissionTransaction="completeDecommissionTransaction"
              @cancelDecommissionTransaction="cancelDecommissionTransaction"/>
        </div>




        <retailer-list-table :rowRaws="retailers" :account_type="getUser.primaryaccount" @rechargeRetailer="rechargeRetailer" @decommission_list_event="decommission_list_event"/>
        <retailer-list-table :rowRaws="retailers" :account_type="getUser.primaryaccount" @rechargeRetailer="rechargeRetailer"  @decommission_list_event="decommission_list_event"/>
      </div>
      </div>



    <div class="flex justify-between bg-white" >
     <div class="flex justify-between p-2 mx-3 bg-white w-64 items-center justify-center">
        <p>Records Per-Page</p>
       <select v-model="perPage">


         <option value="10">10</option>
         <option value="50">50</option>
         <option value="100">100</option>
         <option value="100">500</option>
         <option value="100">1000</option>
         <option value="100">5000</option>
         <option value="100">10000</option>
       </select>
     </div>

      <pagination
          class="w-full bg-white"
          :total-pages="totalPages"
          :total="total"
          :per-page="perPage"
          :current-page="currentPage"
          :has-more-pages="hasMorePages"
          @pagechanged="showMore">
      </pagination>

    </div>

    </div>



</template>

<script>

import add_retailer_to_decommission from "@/components/uicomponents/models/add_retailer_to_decommission";
import { mapGetters } from 'vuex'
import RetailerListTable from "@/components/uicomponents/tables/RetailerListTable";
import add_retailer_model from "@/components/uicomponents/models/add_retailer_model";
import admin_retailer_add_wallet from "@/components/uicomponents/models/admin_retailer_add_wallet";

export default {
  name: "retailerstable",
  props:['country'],
  async created() {
    this.isLoading = true

     await this.$store.dispatch('admins/action_retailer_list',{
      page: this.currentPage,
       perPage: this.perPage,
       country: this.country,
      toast:this.$toast,
      router:this.$router
    });





    await this.filter_retailers()

  },
  components:{
    // Add_retailer_to_decommission,
    add_retailer_to_decommission,
    RetailerListTable,
    add_retailer_model,
    admin_retailer_add_wallet
  },
  data(){
    return {
      show_add_retailer:false,
      show_recharge_model: false,
      show_retailer_to_decommission: false,
      selected_retailer_id:null,
      selected_retailer_mobile:null,
      selected_retailer_name:null,
      total_retailers: null,
      isLoading: false,
      totalPages:null,
      total:100,
      perPage: 10000,
      currentPage:1,
      hasMorePages: false,
      retailers:[],
      column:['Full Name','Email','Mobile','Business Name','Address','Country','Wallet Balance','Account Status'],
      retailer_form:{
        country: null,
        user_id: null,
        business_name: null,
        mobile:null
      }
    }
  },
  computed: {
    ...mapGetters({
      retailer_list: 'admins/retailer_list',
      retailer_list_ghana: 'admins/retailer_list_ghana',
      retailer_list_nigeria: 'admins/retailer_list_nigeria',
      getUser: 'authentication/getUser'

    }),
  },
  methods:{
    completeDecommissionTransaction(data){
      // console.log('retailerstable completeDecommissionTransaction', data)
      // retailer_id: payload.retailer_id,
      //     reason:  payload.reason,
      //     rde_personnel: payload.rde_personnel,
      //     status: payload.status
      this.$store.dispatch('admins/action_decommission_create',{
        retailer_id: data.retailer_id,
        reason: data.reason,
        rde_personnel: data.rde_personnel,
        status: data.status,
        toast:this.$toast,
        router:this.$router
      });

      this.show_retailer_to_decommission= false

    },
    cancelDecommissionTransaction(){

      this.show_retailer_to_decommission= false
    },
    decommission_list_event(data){
      this.selected_retailer_id = data.retailer_id
      this.selected_retailer_mobile = data.mobile
      this.selected_retailer_name = data.name
      this.show_retailer_to_decommission= true

    },
    rechargeRetailer(data){

      this.retailer_form.country = data.country
      this.retailer_form.business_name = data.business_name
      this.retailer_form.mobile = data.mobile
      this.retailer_form.user_id = data.user_id

      this.show_recharge_model = true
    },
    completeRechargeTransaction(data){
      this.retailer_form.country = null
      this.retailer_form.business_name = null
      this.retailer_form.mobile = null
      this.retailer_form.user_id = null
      this.show_recharge_model = false
    },
    cancelRechargeTransaction(){
      this.retailer_form.country = null
      this.retailer_form.business_name = null
      this.retailer_form.mobile = null
      this.retailer_form.user_id = null
      this.show_recharge_model = false
    },
    completeTransaction(data){
      this.$store.dispatch('retailers/action_retailer_create_account',{
        form: data,
        toast:this.$toast,
        router:this.$router
      });

      this.show_add_retailer = false
    },
    cancelTransaction(){
      this.show_add_retailer = false
    },

     download_csv(){
       var csv = '';
       this.retailers.forEach(function(row) {
         csv += row.join(',');
         csv += "\n";
       });
       var hiddenElement = document.createElement('a');
       hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
       hiddenElement.target = '_blank';
       hiddenElement.download = 'retailers.csv';
       hiddenElement.click();
     },
        showMore(data){
      this.currentPage=data
      this.isLoading = true

     this.$store.dispatch('admins/action_retailer_list',{
       country: this.country,
        page: this.currentPage,
       perPage: this.perPage,
        toast:this.$toast,
        router:this.$router
      });

      this.filter_retailers()

    },
    filter_retailers(){
        let myresult = []
        var myretailers;

      if(this.country === 'Ghana'){
        this.total_retailers = this.retailer_list_ghana.total
        myretailers = this.retailer_list_ghana
      }else{
        this.total_retailers = this.retailer_list_nigeria.total
        myretailers = this.retailer_list_nigeria
      }

      console.log('myretailers',myretailers)
      console.log('myretailers.count',myretailers.count)
      console.log('myretailers.per_page',myretailers.per_page)
       // this.total_retailers = this.retailer_list.total

      this.hasMorePages = !(typeof myretailers.next_page_url === 'undefined' || myretailers.next_page_url === null);
      this.totalPages =     Math.round(myretailers.total/myretailers.per_page)

          // myretailers.total/myretailers.per_page


      console.log('this.hasMorePages', this.hasMorePages)
      console.log('this.totalPages', this.totalPages)


    //  console.log("myretailers.data",myretailers.data)



             // !myretailers.data === undefined && myretailers.data?.forEach((item, index)=>{
             myretailers.data?.forEach((item, index)=>{
              myresult.push( [
                  item['name']  ,
                  item['email']  ,
                  item['phone']  ,
                  item['business_name']  ,
                  item['address']  ,
                  item['country'],
                  item['balance'],
                  item['status'] === 0 ? 'Inative': 'Active',
                  item['user_id']
                ])
              })

      console.log('myresult', myresult)



      this.retailers = myresult

      setTimeout(()=>{
        this.isLoading = false
        this.$forceUpdate()
      },4000)

    },
    reload_list(){
      this.$store.dispatch('admins/action_retailer_list',{
        page: this.currentPage,
        toast:this.$toast,
        perPage: this.perPage,
        router:this.$router
      });
      this.filter_retailers()
    }
  }
}
</script>

<style scoped>
.dmcenter {
  display: inline-block;
  position: relative;
  /*left: 50%;*/
  /*transform: translateX(-50%);*/
}
</style>
