<template>
  <div class="flex flex-col justify-between  my-5 w-full  h-full  bg-white rounded-2xl shadow-2xl  text-green-700">

    <div class="flex flex-col items-center justify-center  space-y-2 mt-5">
      <i class="far fa-check-circle text-4xl"></i>
      <p class="text-center  text-4xl ">Payment Received</p>
      <p class="text-center  font-bold text-xs "> Transaction Reference  : {{id}}</p>
    </div>

    <router-link to="/retailer/overview" class="mt-4 mb-5 mx-auto underline text-red-600  border-b-4 border-express text-2xl"> Return Home</router-link>

  </div>

</template>

<script>

export default {
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams)
    console.log(urlParams.has('id')); // true
    console.log(urlParams.get('id'));

    if(urlParams.has('id')){
     let id =  urlParams.get('id')

      this.verify_paystack_transaction(id)
    }else{
      console.log("sdnnsd")
      // this.$router.push('/retailer/overview')
    }

  },
  name: "payment_approved",
  props:['id'],
  data(){
    return {
    }
  },
  methods:{
    verify_paystack_transaction(id){
      console.log("Paystack verification to be completed" , id)
    }
  }
}
</script>

<style scoped>

</style>
