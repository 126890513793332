import moment from "moment";
export const getHumanDate = (mydate) => {
  var serverdate = mydate;
  var year = new Date(serverdate).getFullYear();
  var month = new Date(serverdate).getMonth() + 1;
  var date = new Date(serverdate).getDate();
  var seconds = new Date(serverdate).getSeconds();
  var minutes = new Date(serverdate).getMinutes();
  var hour = new Date(serverdate).getHours();

  year = year > 9 ? "" + year : "0" + year;
  month = month > 9 ? "" + month : "0" + month;
  date = date > 9 ? "" + date : "0" + date;

  seconds = seconds > 9 ? "" + seconds : "0" + seconds;
  minutes = minutes > 9 ? "" + minutes : "0" + minutes;
  hour = hour + 1;
  hour = hour > 9 ? "" + hour : "0" + hour;

  let newDate = `${year}-${month}=${date} ${hour}:${minutes}:${seconds}`;
  return moment(newDate).fromNow();
};

export function randomPassword(length) {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function isValidMACAddress(str) {
  // Regex to check valid
  // MAC_Address
  let regex = new RegExp(
    /^(?:(?:[0-9A-Fa-f]{2}(?=([-:]))(?:\1[0-9A-Fa-f]{2}){5}))$/
  );

  // if str
  // is empty return false
  if (str == null) {
    return false;
  }

  // Return true if the str
  // matched the ReGex
  if (regex.test(str) == true) {
    return true;
  } else {
    return false;
  }
}

export function checkDataPlan(amount) {
  let datapack = 0;
  let duration = 1;

  if (amount === 50 || amount === 0.8) {
    datapack = 104857600;
    duration = 1;
    //  Daily Capped 100mb Plan
  } else if (amount === 100 || amount === 1.5) {
    //  datapack = 314572800
    datapack = 367001600;
    duration = 1;
    //   Daily Capped 300mb Plan
  } else if (amount === 300 || amount === 5) {
    datapack = 777;
    duration = 2;
    //  Daily Unlimited Plan
  } else if (amount === 1000 || amount === 13) {
    datapack = 777;
    duration = 3;
    //  3 Days Daily Unlimited Plan
  } else if (amount === 1500 || amount === 25) {
    datapack = 777;
    duration = 7;
    //  7 Days Daily Unlimited Plan
  } else if (amount === 3000 || amount === 45) {
    datapack = 777;
    duration = 14;
    //  14 Days Daily Unlimited Plan
  } else if (amount === 6000 || amount === 80) {
    datapack = 777;
    duration = 30;
  }

  return { datapack, duration };
}
