<template>
    <div class="p-5 flex flex-col text-expressgray">
      <div class="flex flex-col border-2 shadow-2xl  bg-white  p-5 rounded">
        <h2 class="w-full font-semibold text-express text-2xl  ">Journey Plan</h2>
        <div class="flex justify-between  mt-4">
          <div class="flex flex-col space-y-4">
            <h2 class="font-extralight">Personnel Name</h2>
            <h3>Ogunleye Seye</h3>
          </div>

          <div class="flex flex-col space-y-4">
            <h2  class="font-extralight">Phone</h2>
            <h3>08145682628</h3>
          </div>

          <div class="flex flex-col space-y-4">
            <h2  class="font-extralight">Email</h2>
            <h3>ogunleye.seye@tizeti.com</h3>
          </div>

          <div class="flex flex-col space-y-4">
            <h2  class="font-extralight">Created</h2>
            <h3>January 16, 2022</h3>
          </div>

        </div>
      </div>


      <div class="w-full bg-white shadow-2xl rounded p-5  mt-3 flex ">
          <div class="w-4/5 p-3 flex flex-col">
              <div class="h-10 w-full flex  justify-between items-center">
                <div class="flex space-x-4 items-center">
                    <h3>Week 3</h3>
                  <i class="fas fa-angle-left px-6 py-3 border-2 cursor-pointer hover:bg-green-500 hover:text-white"></i>
                  <i class="fas fa-angle-right px-6 py-3 border-2 cursor-pointer  hover:bg-green-500 hover:text-white"></i>
                </div>
                <h3 class="text-express">Jan 17  - Jan 22</h3>
              </div>



            <div class="grid grid-cols-5 gap-0 mt-4">
              <div class="bg-inboxbg p-5 border-l-4 border-r-2">Monday</div>
              <div class="bg-inboxbg p-5 border-l-2 border-r-2">Tuesday</div>
              <div class="bg-inboxbg p-5 border-l-2 border-r-2">Wednesday</div>
              <div class="bg-inboxbg p-5 border-l-2 border-r-2">Thursday</div>
              <div class="bg-inboxbg p-5 border-l-2 border-r-4">Friday</div>

              <div class="flex flex-col space-y-3 p-3 border-l-4 border-r-2 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo road, Oworoshoki, Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-2 border-b-2">
                <h3>Nduvins Nig. Enterprises-1</h3>
                <p>40 Oworo road, Oworoshoki, Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-2 border-b-2">
                <h3>Standonventure</h3>
                <p>3b fakoya street, akowonjo, egbeda</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-2 border-b-2">
                <h3>A-B-D-Communication-2</h3>
                <p>68, Iyana ipaja road alaguntan bus stop, iyaja ipaja,</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-4 border-b-2">
                <h3>Try and See Technologies</h3>
                <p>12 buluro street aluminum village dopemu Agege</p>
              </div>


              <div class="flex flex-col space-y-3 p-3 border-l-4 border-r-2 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-2 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-2 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-2 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-4 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>



              <div class="flex flex-col space-y-3 p-3 border-l-4 border-r-2 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-2 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-2 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-2 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-4 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>




              <div class="flex flex-col space-y-3 p-3 border-l-4 border-r-2 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-2 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-2 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-2 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>

              <div class="flex flex-col space-y-3 p-3 border-l-2 border-r-4 border-b-2">
                <h3>Top Most Media Concept</h3>
                <p>40 Oworo Rd, Oworoshoki,Bariga</p>
              </div>





            </div>


           </div>
        <div class="w-1/5 p-3 flex flex-col items-center justify-center space-y-4">
          <div class="flex flex-col  text-center rounded-full p-5 text-green-500 text-lg cursor-pointer">
            <i class="fas fa-edit"></i>
            <h3>Edit</h3>
          </div>

          <div class="flex flex-col  text-center  rounded-full p-5 text-green-500 text-lg cursor-pointer">
            <i class="fas fa-cloud-download-alt"></i>
            <h3  class="">Download</h3>
          </div>



        </div>




      </div>


    </div>
</template>

<script>
export default {
  name: "IndividualJourneyPlan"
}
</script>

<style scoped>

</style>
